import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Add from '@material-ui/icons/Add'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { CREATE_ISSUE, GET_ISSUES } from '../../../apollo/queries/issue'

class IssuePopup extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCreate = () => {
    this.props.create({
        variables: {input: {description: this.state.value, category: this.props.category.id}},
        refetchQueries: [{query: GET_ISSUES}]
    })
    this.setState({open: false })
  }

  render() {
    return (
      <div>
        <Button size="small" color="primary" onClick={this.handleClickOpen}>
          <Add />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create Issue Description</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {this.props.category ? "Create a new description for " + this.props.category.name : "Select a category first!"}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Description"
              fullWidth
              onChange={e => this.setState({ value: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCreate} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(
    graphql(CREATE_ISSUE, {name: 'create'})
    )(IssuePopup) 