import { gql } from '@apollo/client'

export const UPDATE_HARDWARE_MUTATION = gql`
  mutation UpdateHardwareStatusMutation($input: UpdateHardwareStatusMutationInput!) {
    updateHardwareStatusMutation(input: $input){
        ok
        hardwarestatus {
          id
          time
          data
          summary
          user {
            username
          }
        }
    }
  }
`;

export const CREATE_HARDWARE = gql`
  mutation CreateHardwareStatus($input: HardwareStatusCreateGenericType!) {
    createHardwareStatus(newHardwarestatus: $input){
        ok
        hardwarestatus {
          id
          time
          data
          summary
          user {
            username
          }
        }
    }
  }
`;

export const DELETE_HARDWARE = gql`
  mutation DeleteHardwareStatus($input: ID!) {
    deleteHardwareStatus(id: $input){
        ok
        hardwarestatus {
          id
          time
          data
          summary
          user {
            username
          }
        }
    }
  }
`;