import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PARTIAL_HEADERS } from '../../../apollo/queries/partialHeaders'
import { Query } from 'react-apollo'
import format from 'date-fns/format'

import { scaleLinear, scaleLog, scaleOrdinal, scaleTime, scaleUtc } from 'd3-scale'
import { extent, bisector, mean} from 'd3-array'
import { select, pointer as D3Pointer} from 'd3-selection'
import { line, stack } from 'd3-shape'
/*import * as layout from 'd3-shape'*/
import { axisTop, axisRight, axisLeft, axisBottom} from 'd3-axis'
import * as d3Zoom from 'd3-zoom'
import * as d3Dispatch from 'd3-dispatch'
import { transition } from 'd3-transition'
import addDays from 'date-fns/addDays'
import parseISO from 'date-fns/parseISO'
import './graph.css'



const margin = 30

const MARGIN = {
    top: margin,
    bottom: margin+10,
    left: margin+20,
    right: margin+90
}

const SUN_SEARCH_COLORS = {'mo': '#00429d', 'ca': '#73a2c6', 'sh': '#f4777f', 'lo': '#93003a' }

var dispatch;

class PartialPlot extends React.PureComponent {

    WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
    HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom

    shiftRangeByDays(days){
      this.props.setStart(addDays(this.props.start, days))
      this.props.setEnd(addDays(this.props.end, days))
    }

    render(){
      return(
        <div style={{height: "500px"}} >
        <svg className="statusGraph" ref={node => this.node = node}
          className="detailGraph"
          width={this.props.size[0]} height={this.props.size[1]} >
          </svg>
          <div style={{position: "absolute", top: "470px"}}>
          <Button onClick={() => this.shiftRangeByDays(-1)} >Back</Button>
          <Button disabled={this.props.start > (new Date() - 100000)} onClick={() => this.shiftRangeByDays(1)} >Forward</Button>
          </div>
        </div>)
    }

    componentDidMount(){
      this.createGraph()
    }

    componentDidUpdate(){
        const svg = select(this.node).selectAll("svg")
        svg.remove()
        select(this.node).select("#value-box").remove()
        this.createGraph()
    }

    createGraph(){
      const data = this.props.data.partialHeaders.slice().reverse()
      var svg = select(this.node)
      const plotWidth = 1100
      const timeRange = [this.props.start, this.props.end]

      var mainChart = mainPlot().width(plotWidth).height(300).yOffset(20)
                .xRange(timeRange)

      var temperatureChart = temperaturePlot().width(plotWidth).height(140).yOffset(mainChart.yOffset() + mainChart.height())
                          .xRange(timeRange).yRange([-5, 5])

      dispatch = d3Dispatch.dispatch("zoomed", "hovered")

      var values = valueBox().position([1120, MARGIN.top + mainChart.yOffset() - 23])
      //const tz = this.props.data.currentLocation.timezone
      //var data = []
/*      this.props.data.partialHeaders.forEach((d,i) => {
        console.log(d.time)
        console.log(JSON.parse(d.data))
      })*/
      svg.datum({headers: data, searches: sunSearch(data)}).call(mainChart)
      svg.datum(getTempData(data)).call(temperatureChart)

      svg.datum(null).call(values)
    }
}






const ListContent = props => {

  const [end, setEnd] = React.useState(new Date())
  const [start, setStart] = React.useState(addDays(new Date(), -1))

  return(
  <Query query={PARTIAL_HEADERS} variables={{input: props.instrumentID, start: start, end: end}}>
	{/*<Query query={PARTIAL_HEADERS} variables={{input: props.instrumentID}}>*/}
	{({error, loading, data}) => {
		if (loading) return <p>Loading...</p>;
        if (error) return <p>Error from Instruments</p>;
/*        var ret = []
        data.instrument.partialHeaders.forEach((d,i)=>{
        	ret.push(<div><p>{d.time}</p><span style={{whiteSpace: "pre-line"}} >{JSON.stringify(JSON.parse(d.data), null, 2)}</span></div>)
        })*/
        return(<PartialPlot size={[1600,600]} data={data.instrument} setStart={setStart} setEnd={setEnd} start={start} end={end} />)
        //return(ret)
	}}
	</Query>
  )
}





class ListDialog extends React.PureComponent {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          status lines
        </Button>
        <Dialog
        fullWidth
          maxWidth="xl"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">status lines for {this.props.panID}</DialogTitle>
          <DialogContent>
          	<ListContent instrumentID={this.props.instrumentID} date={format(new Date(), 'yyyy-MM-dd')} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ListDialog;

/*format(new Date(), 'yyyy-MM-dd')*/


function mainPlot(){
    var width = 500
    var height = 300
    var widthHist = 100
    var graphLabel
    var yRange = 0
    var xRange = 0
    var yOffset = 0
    var valueKey = 'totcol'
    const RADIUS = 7
    const Y_PADDING = 7
    const X_PADDING = 2
    const RANGE_RMS = [0,0.3]
    const RANGE_FWHM = [0,4]
    const RANGE_DELTA = [-1,1]
    const DELTA_OPACITY_FACTOR = 3
    const DELTA_COLOR = "#39A96B"
    

    function plot(selection){
        selection.each(function(data, i) {


            const searches = data.searches
            data = data.headers

            const WIDTH = width - MARGIN.left - MARGIN.right
            const HEIGHT = height - MARGIN.top - MARGIN.bottom

            var zoomX = d3Zoom.zoom().on("zoom", zoomedX)


            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr('y', yOffset)
           
            var group = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

            var xScale = scaleUtc().range([0,WIDTH]).domain(xRange).nice()
            var yScaleTop = scaleLinear().range([-RADIUS -Y_PADDING, -HEIGHT/2]).domain(RANGE_RMS)
            var yScaleBottom = scaleLinear().range([RADIUS + Y_PADDING, HEIGHT/2]).domain(RANGE_FWHM)
            //var scaleDelta = scaleLinear().range([-DELTA_MAX_LENGTH, DELTA_MAX_LENGTH]).domain(RANGE_DELTA)
            //define axes as grid        

            var xAxis = axisBottom(xScale).tickSize(0)//.ticks(5)
            var yAxisTop = axisLeft(yScaleTop).tickSize(0).ticks(5)
            var yAxisBottom = axisLeft(yScaleBottom).tickSize(0).ticks(5)
            var xGrid = axisTop().scale(xScale).tickSize(-HEIGHT).tickFormat('')//.ticks(5)
            var yGridTop = axisRight().scale(yScaleTop).tickSize(-WIDTH).tickFormat('').ticks(5)
            var yGridBottom = axisRight().scale(yScaleBottom).tickSize(-WIDTH).tickFormat('').ticks(5)

/*                //add heading, graph label
            group.append("text")
                .attr("font-size", "16px")
                .attr("y", -15)
                .attr("class", "station-label")
                .text("test")*/

            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)
            .style("pointer-events", "all")
            //.style("opacity", .3)

            var xGridG = group.append("g")
                    //.attr("transform", "translate(0," +MARGIN.top + ")")
                    .call(xGrid)
                    .attr("class","grid")

            var yGridTopG = group.append("g").call(yGridTop).attr("class", "grid")
                              .attr("transform", "translate(" + WIDTH + "," + HEIGHT/2 + ")")
            var yGridBottomG = group.append("g").call(yGridBottom).attr("class", "grid")
                              .attr("transform", "translate(" + WIDTH + "," + HEIGHT/2 + ")")    



            //call xaxis
            var xAxG = group.append("g")
                    .attr("transform", "translate(0," + HEIGHT + ")")
                    .call(xAxis)
                    .attr("class", "axis x")
                    .style("font-size", "12px")
                    .attr("pointer-events", "none")

            xAxG.append("rect")
              .attr("id", "zoomx")
              .attr("width", WIDTH)
              .attr("height", MARGIN.bottom)
              .style("visibility", "hidden")
              .attr("pointer-events", "all")
              .attr("cursor", "pointer")
              .call(zoomX);

            var yAxTopG = group.append("g")
                    .attr("transform", "translate(0," + HEIGHT/2 + ")")
                    .call(yAxisTop)
                    .attr("class","axis y")
                    .style("font-size", "10px")
                    .attr("pointer-events", "none")
                    

            var yAxBottomG = group.append("g")
                    .attr("transform", "translate(0," + HEIGHT/2 + ")")
                    .call(yAxisBottom)
                    .attr("class","axis y")
                    .style("font-size", "10px")
                    .attr("pointer-events", "none")


            //axis titles
            group.append("text").attr("x", -50).attr("y", HEIGHT/4).text("rms").style("font-size", "12px")
            group.append("text").attr("x", -50).attr("y", HEIGHT/4 + 10).text("(x,y)").style("font-size", "10px")
            group.append("text").attr("x", -50).attr("y", 3*HEIGHT/4).text("fwhm").style("font-size", "12px")
            group.append("text").attr("x", -45).attr("y", 3*HEIGHT/4 + 10).text("(x,y)").style("font-size", "10px")

            //sun search legend
            const SPACING = 18

            var legend = group.append("g").attr("transform", "translate(" + (WIDTH+30) + ",20)").style("font-size", "10px")

            legend.append("text").attr("y", -15).text("sun searches:").style("font-size", "11px")

            legend.selectAll(".legend")
            .data(Object.values(SUN_SEARCH_COLORS))
            .enter().append("circle").attr("cy", (d,i) => i*SPACING).attr("r", RADIUS).attr("fill", d => d)

            legend.selectAll(".label").data(['moon', 'camera', 'short', 'long']).enter()
            .append("text").attr("x", 14).attr("y", (d,i) => i*SPACING + 4).text(d => d)

            legend.append("circle").attr("cy", 4.5*SPACING).attr("r", RADIUS-1).attr("stroke", "grey").attr("fill", "none").style("stroke-width", 2)
            legend.append("text").attr("y", 4.5*SPACING + 4).attr("x", 14).text("failed")

            legend.append("circle").attr("cy", 6*SPACING).attr("r", RADIUS).attr("fill", "grey")
            legend.append("line").attr("x1",0).attr("y1",6*SPACING)
                    .attr("x2", 10).attr("y2", 6*SPACING - 7).style("stroke", DELTA_COLOR).style("stroke-width", 3)
            legend.append("text").attr("y", 6*SPACING + 4).attr("x", 14).text("delta (direction)")

            legend.append("text").attr("y", 6*SPACING + 18).attr("x", 8).text("opacity ≈ offset")









            group.append("defs").append("clipPath")
                .attr("id", "clipMain")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipMain)")
                .on("dblclick", doubleClick)




            const circles = main.selectAll(".header").data(data).enter().append("circle")
                .attr("cx", d => xScale(new Date(d.time)))
                .attr("cy", HEIGHT/2)
                .attr("r", 2)
                .style("fill",  "grey")

            if(data.length !== 0){

            var focus = main.append('g').style('display', 'none');
            focus.append('circle')
                .attr('id', 'focusCircle')
                .attr('r', 2)
                .attr('class', 'focusCircle');

            //sweeping of the plot
            var bisectTime = bisector(d=>new Date(d.time)).left

            main.append('rect')
                .attr('class', 'overlay')
                .attr('width', WIDTH)
                .attr('height', HEIGHT)
                .on('mouseover', function() { focus.style('display', null); })
                .on('mouseout', function() { focus.style('display', 'none'); dispatch.call("hovered", null, null); })
                .on('mousemove', function(event) { 
                    var mouse = D3Pointer(event);
                    var mouseTime = new_xScale.invert(mouse[0]);
                    var i = bisectTime(data, mouseTime); // returns the index to the current data item

                    var d0 = (i-1) in data ? data[i - 1] : data[i]
                    var d1 = i in data ? data[i] : data[i-1]
                    // work out which date value is closest to the mouse
                    var d = mouseTime - new Date(d0.time) > new Date(d1.time) - mouseTime ? d1 : d0;

                    var x = new_xScale(new Date(d.time));
                    var y = HEIGHT/2

                    focus.select('#focusCircle')
                        .attr('cx', x)
                        .attr('cy', y);

                    dispatch.call("hovered", this, JSON.parse(d.data))
                });
            }

            const searchCircles = main.selectAll(".searches").data(searches).enter().append("g")
                                  .attr("transform", d => "translate(" + xScale(d.time) + "," + HEIGHT/2 + ")")
                                  .style("stroke", d => SUN_SEARCH_COLORS[d.type]).style("stroke-width", 3)

            searchCircles.append("circle").attr("r", RADIUS).style("stroke-width", 2)
                      .style("fill", d => d.ok ? SUN_SEARCH_COLORS[d.type] : 'transparent' )
                      .style("pointer-events", "all")
                      .append("title").text( d => JSON.stringify(d, null, 2))

            const notMoon = searchCircles.filter(d => d.type !== 'mo')


            notMoon.append("line").attr("x1", -X_PADDING).attr("x2", -X_PADDING).attr("y1", -RADIUS - Y_PADDING)
                          .attr("y2", d => d.rms ? yScaleTop(d.rms[0]) : -RADIUS - Y_PADDING)

            notMoon.append("line").attr("x1", X_PADDING).attr("x2", X_PADDING).attr("y1", -RADIUS - Y_PADDING)
                          .attr("y2", d => d.rms ? yScaleTop(d.rms[1]) : -RADIUS - Y_PADDING)

            notMoon.append("line").attr("x1", -X_PADDING).attr("x2", -X_PADDING).attr("y1", RADIUS + Y_PADDING)
                          .attr("y2", d => d.fwhm ? yScaleBottom(d.fwhm[0]) : RADIUS + Y_PADDING)

            notMoon.append("line").attr("x1", X_PADDING).attr("x2", X_PADDING).attr("y1", RADIUS + Y_PADDING)
                          .attr("y2", d => d.fwhm ? yScaleBottom(d.fwhm[1]) : RADIUS + Y_PADDING)

            notMoon.append("line").attr("x1", 0).attr("y1", 0)
            .attr("x2", d => d.delta ? (RADIUS + Y_PADDING) * d.delta[0]/ Math.sqrt(d.delta[0]*d.delta[0] + d.delta[1]*d.delta[1]) : 0)
            .attr("y2", d => d.delta ? (RADIUS + Y_PADDING) * d.delta[1]/Math.sqrt(d.delta[0]*d.delta[0] + d.delta[1]*d.delta[1]) : 0)
            .style("stroke", DELTA_COLOR).style("opacity", d => d.delta ? Math.sqrt(d.delta[0]*d.delta[0] + d.delta[1]*d.delta[1]) * DELTA_OPACITY_FACTOR: 0 )




            






            function update(){
              xAxG.call(xAxis.scale(new_xScale))
              xGridG.call(xGrid.scale(new_xScale))
              circles.attr("cx", d => new_xScale(new Date(d.time)))
              searchCircles.attr("transform", d => "translate(" + new_xScale(d.time) + "," + HEIGHT/2 + ")")
            }

           

            var new_xScale = xScale;
            function zoomedX({transform}){
                new_xScale = transform.rescaleX(xScale)
                dispatch.call("zoomed", this, transform)
                update()
            }
            function doubleClick(){
                //new_xScale = xScale
                //xAxG.select('#zoomx').call(zoomX.transform, d3Zoom.zoomIdentity)
                //yAxG.select('#zoomx').call(zoomY.transform, d3Zoom.zoomIdentity)
                //update()
            }

        });
    }

    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    };

    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    };
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    };

    plot.yRange = function(value) {
        if (!arguments.length) return yRange;
        yRange = value;
        return plot;
    };

    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.valueKey = function(value) {
        if (!arguments.length) return valueKey;
        valueKey = value;
        return plot;
    }
    plot.graphLabel = function(value) {
        if (!arguments.length) return graphLabel;
        graphLabel = value;
        return plot;
    }

    return plot;
}

const SUN_SEARCH = ['lo', 'sh', 'ca', 'mo']

function sunSearch(data){

  var ret = []
  var lastValuesOK = [new Date(0), new Date(0), new Date(0), new Date(0)]
  var lastValues = [new Date(0), new Date(0), new Date(0), new Date(0)]



  data.forEach((d,i) => {

    const json = JSON.parse(d.data)



    json['TIMELASTFINDSUN'].split(';').forEach((d,i) => {
      const date = parseISO(d)
      if( date > lastValues[i] ){
        ret.push({time: date, ok: false, type: SUN_SEARCH[i]})
        lastValues[i] = date
      }
    })
    var numberSearches = 0
    json['TIMELASTFINDSUNOK'].split(';').forEach((d,i) => {
      const date = parseISO(d)
      
      if( date > lastValuesOK[i] ){
        const last = ret[ret.length-1]

        if( last.ok === false && last.type === SUN_SEARCH[i] && (date - last.time) < 180000){
          ret.pop()
        }
        numberSearches = numberSearches + 1
        ret.push({time: date, ok: true, type: SUN_SEARCH[i], rms: undefined, fwhm: undefined, delta: undefined})
        lastValuesOK[i] = date
      }
    })
    if(numberSearches > 0){
      const index = ret.length - numberSearches
      try{
        ret[index].rms = json['RMSLASTFINDSUNOK'].split(';')
        ret[index].fwhm = json['FWHMLASTFINDSUNOK'].split(';')
        ret[index].delta = json['DELTALASTFINDSUNOK'].split(';')
      }catch(err){
        console.log("doesnt have sun search params(rms, fwhm, delta)")
        console.log(err.message)
      }
    }

  })
  return ret
}


function getTempData(data){
  var ret = []
  data.forEach((d,i) => {
    const json = JSON.parse(d.data)
    ret.push({time: parseISO(json['TIMELASTTEMP']), temp: json['LASTTEMP'], setTemp: json['SETTEMP']})
  })
  return ret
}



function temperaturePlot(){
    var width = 700
    var height = 200
    var yRange = 0
    var xRange = 0
    var valueKey = 'temp'
    var yOffset = 300
    var threshold = null
    var config = {type: '', boxPlot:false}
    var boxWidth = 20
  const fontSize = '14px'
    const MARGIN = {
        top: 30,
        bottom: 5,
        left: 50,
        right: 120
    }

    function plot(selection){
        selection.each(function(data, i) {
            const WIDTH = width - MARGIN.left - MARGIN.right
            const HEIGHT = height - MARGIN.bottom - MARGIN.top

            dispatch.on("zoomed."+valueKey, zoomedX)
            //dispatch.on("hovered."+valueKey, hovered)
            var zoomY = d3Zoom.zoom().on("zoom", zoomedY)

            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr("y", yOffset)
                                
            var group = svg.append("g").attr("id", "aux-plot-" + valueKey)
                    .attr("transform", "translate(" + MARGIN.left + ","+ MARGIN.top + ")")




            var xScale = scaleUtc().range([0,WIDTH]).domain(xRange).nice()
            var yScale = scaleLinear().range([HEIGHT, 0]).domain(yRange).nice()
            var new_xScale = xScale
            var new_yScale = yScale

            //define axes as grid        
            var xGrid = axisTop().scale(xScale).tickSize(-HEIGHT).tickFormat('').ticks(5)
            var yGrid = axisRight().scale(yScale).tickSize(-WIDTH).tickFormat('').ticks(5)
            var yAxis = axisLeft(yScale).tickSize(0).ticks(5)


            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)
            .style("pointer-events", "all")
            .style("opacity", .3)




            //ygrid
            var yGridG = group.append("g")
                    .attr("transform", "translate("+(WIDTH)+",0)")
                    .call(yGrid)
                    .attr("class","grid y")
            //call xgrid    
            var xGridG = group.append("g")
                    //.attr("transform", "translate(0," +MARGIN.top + ")")
                    .call(xGrid)
                    .attr("class","grid")

            //call yaxis
            var yAxG = group.append("g")
                    .call(yAxis)
                    .attr("class","axis y")
                    .style("font-size", "9px")
                    .attr("pointer-events", "none")

            yAxG.append("rect")
              .attr("id", "zoomy")
              .attr("width", MARGIN.left)
              .attr("height", HEIGHT)
              .style("visibility", "hidden")
              .attr("pointer-events", "all")
              .attr("cursor", "pointer")
              .attr("transform", "translate(" + (-MARGIN.left) + ",0)")
              .call(zoomY);



            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")

            //if(data.length < 1){ return }


            const meanTemp = data.length > 0 ? Math.round(mean(data, d => d.temp)*100)/100 : 0
            const setTemp = data.length > 0 ? Number(data[0].setTemp) : 0


            group.append("text")
                .attr("font-size", fontSize)
                .style("white-space", "pre")
                .attr("y", -MARGIN.top/4)
                .text("f(t) = T(t) - " + meanTemp +  " (mean of day)                                  ∆T = mean - Tˢᵉᵗ = " + meanTemp + " - " + setTemp + " = " + (Math.round((meanTemp - setTemp)*100)/100) )
                //.text(config.type)

            var tempLine = line()
                    .x(d => xScale(d.time))
                    .y(d => yScale(Number(d.temp) - meanTemp))

            var path = main.append("path")
                .datum(data)
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", SUN_SEARCH_COLORS['lo'])
                .style("stroke-width", 2)
                .attr("d", tempLine)


            var focus = main.append('g').style('display', 'none');
            focus.append('circle')
                .attr('id', 'focusCircle')
                .attr('r', 4)
                .attr('class', 'focusCircle');

            function update() {
                
                xGridG.call(xGrid.scale(new_xScale))
                yGridG.call(yGrid.scale(new_yScale))
                yAxG.call(yAxis.scale(new_yScale))
                var tempLine = line()
                    .x(d => new_xScale(d.time))
                    .y(d => new_yScale(Number(d.temp) - meanTemp))

                path.attr("d", tempLine)


            }
            function zoomedX(transform){
                new_xScale = transform.rescaleX(xScale)
                update()
            }
            function zoomedY({transform}){
                new_yScale = transform.rescaleY(yScale)
                update()
            }

/*
            function hovered(d) {

                if(d === null ){ focus.style('display', 'none'); return;}
                focus.style('display', null)
                focus.select("#focusCircle").attr("cx", new_xScale(new Date(d.time))).attr("cy", new_yScale(d[valueKey]))

            }*/
        });
    }

    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    };

    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    };

    plot.yRange = function(value) {
        if (!arguments.length) return yRange;
        yRange = value;
        return plot;
    };

    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.valueKey = function(value) {
        if (!arguments.length) return valueKey;
        valueKey = value;
        return plot;
    }
    plot.threshold = function(value) {
        if (!arguments.length) return threshold;
        threshold = value;
        return plot;
    }
    plot.config = function(value) {
        if (!arguments.length) return config;
        config = value;
        return plot;
    }
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    }
    
    return plot;
}


function valueBox(){
    var height = 800
    var width = 600
    var position = [0,0]
    var valueKeys = []

    var medians = []
    var columns = ['parameter',' ', 'value', 'median']

    function plot(selection){
        selection.each(function(data, i){

        dispatch.on("hovered.valueBox", update)
        
        if(data === null || data === undefined){
            data = {}
            valueKeys.forEach(function(d){
                data[d] = Number.isInteger(d) ? ' ' : '-'
            })
        }
        var wrapper = select(this).append("foreignObject").attr("width", width).attr("height", height).attr("id", "value-box")
                        .attr("transform", "translate(" + position[0] + "," + position[1] + ")").append("xhtml:body")
        var table = wrapper.append("xhtml:span").attr("transform", "translate(" + position[0] + "," + position[1] + ")")
                            .style("white-space", "pre-line").style("width", "300px").text(JSON.stringify(data, null, 2))


        function update(data){
            table.text(JSON.stringify(data, null, 2))
        }
        })
    }
    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    }
    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    }
    plot.position = function(value) {
        if (!arguments.length) return position;
        position = value;
        return plot;
    }
    plot.valueKeys = function(value) {
        if (!arguments.length) return valueKeys;
        valueKeys = value;
        return plot;
    }
    plot.medians = function(value) {
        if (!arguments.length) return medians;
        medians = value;
        return plot;
    }


    return plot;
}