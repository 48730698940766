import * as React from 'react';
import { UPDATE_INSTRUMENT_MUTATION , GET_INSTRUMENTS} from '../../../apollo/queries/instruments'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const InstrumentStaticNotes = (props) => {
	const [notes, changeNotes] = React.useState(props.data.notes)
	const [response, setResponse] = React.useState([false, ""])

	function handleUpdate(){
	    let variables_post = {
	      id: props.data.id,
	      notes: notes,
	    };
	    console.log("Updating Instrument static notes: ", variables_post)
		props.update({
			variables: {input: variables_post},
        	update: (store, {data: { updateInstrumentMutation }}) => {
	          	if(!updateInstrumentMutation.ok){
	          		setResponse([true, "error saving notes"])
	          		console.log("Could not set Instrument static notes, response: ",updateInstrumentMutation)
	          		return};
	            //console.log("Instrument static notes updated: ",updateInstrumentMutation)
	          	const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
	          	const index = cache.instruments.findIndex(e => e.panId === props.data.panId)
	          	cache.instruments[index] = updateInstrumentMutation.instrument
	          	store.writeQuery({query: GET_INSTRUMENTS, data: cache})
	          	setResponse([true, "static notes got saved!"])
        	}
		})
	}

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setResponse([false, ""]);
  };

	return(<div>
		    <TextField
              multiline
              id="notes"
              label="notes"
              fullWidth
              defaultValue={notes}
              onChange={e => changeNotes(e.target.value)}
            />
            <Button onClick={handleUpdate}>save notes</Button>
            <Snackbar open={response[0]} autoHideDuration={6000} onClose={handleClose}>
		        <Alert onClose={handleClose} severity={response[1]==="static notes got saved!" ? "success" : "error"}
		        		elevation={6} variant="filled">
		          {response[1]}
		        </Alert>
		    </Snackbar>
           </div>
		)

}

export default compose(
  graphql(UPDATE_INSTRUMENT_MUTATION , {name: 'update'}))(InstrumentStaticNotes)