import * as React from 'react';
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QflagPlot from '../../components/QflagPlot3'
import addDays from 'date-fns/addDays'



const INST_QUERY = gql `
{
	instruments {
		id
		panId
		dataheader {
			id
			fileName
			location {
				name
			}
			instrument {
				panId
			}
		}
	}
}
`

const HEADER_QUERY = gql `
query DataHeader($id: Int!, $gas: String!, $start: DateTime, $end: DateTime, $modulo: Boolean){
	dataHeader(id: $id){
		id
		fileName
		content
		qflagCount(gas: $gas, start: $start, end: $end, modulo: $modulo)
	}
}
`

/*const QFLAG_QUERY = gql `
query Qflags($panId: Int!, $location: String!, $rcode: String!, $gas: String!){
	get
}
`*/

const HeaderBlock = (props) => {
	const [timeRange, setTimeRange] = React.useState(props.timeRange)
	const headerQuery = useQuery(HEADER_QUERY, {variables:{id:props.id, gas:"NO2", start:timeRange[0], end:timeRange[1], modulo: true},
												returnPartialData: true, partialRefetch:true})

												//start: timeRange[0], end: timeRange[1]}})

	//if(headerQuery.loading || headerQuery.refetching) return "loading";
	if(headerQuery.loading) return "loading";
	//return(JSON.stringify(headerQuery.data))
	//console.log(headerQuery.data)
	return(<QflagPlot data={headerQuery.data.dataHeader.qflagCount} size={[1000,200]} 
		refetch={headerQuery.refetch} timeRange={timeRange} setTimeRange={setTimeRange} />)
}


const DataExplorer = (props) => {
	const inst_query = useQuery(INST_QUERY)

	if(inst_query.loading) return <p>loading...</p>;

	const data = inst_query.data.instruments
	const timeRange = [addDays(new Date(), -365), new Date()]



	return(
		<Paper>
		{data.map((d,i) => (
			<Card>
			<CardContent>
			<Typography variant="h4" >{d.panId}</Typography>
			<Typography variant="h5" >{d.dataheader.length}</Typography>
			{d.dataheader.map((d,i) => (
				<Accordion TransitionProps={{ unmountOnExit: true }} >
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<p>{d.fileName}</p>
				</AccordionSummary>
				<AccordionDetails>
				<HeaderBlock timeRange={timeRange} {...d} />
				</AccordionDetails>
				</Accordion>
			))}
			</CardContent>
			</Card>
			))}
		</Paper>)
}

export default DataExplorer;