import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import DataDaysPlot from '../../components/timelines/DataDays'

const DAYS = gql `
{
	allDataHeaders {
		fileName
		days
	}
}
`

const timeRange = [new Date().setFullYear(2016),new Date()]

const AllDataPlot = (props) => {
	const daysQuery = useQuery(DAYS)

	if (daysQuery.loading) return <p>Loading ...</p>;

	const data = daysQuery.data.allDataHeaders

	return (<div>
			{data.map((d,i) => (
					<div>
					<p>{d.fileName}</p>
					<DataDaysPlot data={d.days} width={1200} height={10} timeRange={timeRange} />
					</div>
				) )}
			</div>)
}

export default AllDataPlot