import * as React from 'react';

import {
  Grid,
  Table,
  TableHeaderRow,
  SearchPanel,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import {
  DataTypeProvider,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';

import CommentIcon from '@material-ui/icons/Message'
import ComputerIcon from '@material-ui/icons/Computer'
import IssueIcon from '@material-ui/icons/Warning'
import IssueClosedIcon from '@material-ui/icons/Check'
import DeploymentIcon from '@material-ui/icons/LocationOn'
import StatusIcon from '@material-ui/icons/Settings'

import Typography from '@material-ui/core/Typography';
import { TIME_FORMAT, TextTypeProvider, TimeTypeProvider} from '../containers/apollo/utils/Editors'
import * as moment from 'moment'

import { STATUS_COLORS } from '../constants'
import { CommentEditWidget } from '../containers/apollo/utils/CommentDialog'


const GetIcon = id => {
  switch(id){
    case "comment":
      return <CommentIcon color="primary" />
    case "deployment":
      return <DeploymentIcon color="primary" />
    case "hardware":
        return <ComputerIcon color="primary" />
    case "calibration":
        return <ComputerIcon color="primary" />
    case "status":
      return <StatusIcon style={{'color': STATUS_COLORS['operational']}} />
    case "issue_opened":
      return <IssueIcon style={{'color': STATUS_COLORS['hold due to issue']}} />
    case "issue_closed":
      return <IssueClosedIcon style={{'color': STATUS_COLORS['hold due to issue']}} />
    case "issue_action":
        return <IssueIcon style={{'color': STATUS_COLORS['hold due to issue']}} />
    default:
      return null
  }
}


//const TitleTypeFormatter = ({value, row}) => (row.type !== "comment" ? value : <CommentEditWidget



const LogTypeFormatter = ({value}) => (GetIcon(value))

const LogTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={LogTypeFormatter}
    {...props}
  />
)

const logEntry = (element, id) => {

    const {time, type, content} = element
    const user = content.user ? content.user.username : "unknown"
    const icon = GetIcon(type)
    var title, body;

    switch(type){
        case "comment":
            title = "comment by " + user
            body = content.text
            break;
        case "status":
            title = content.status.name
            body = content.description
            break;
        case "issue_opened":
            title = content.issue.category.name + " opened"
            body = content.issue.description
            break;
        case "issue_closed":
            title = content.issue.category.name + " closed"
            body = content.issue.description
            break;
        case "issue_action":
            title = "action on " + content.category
            body = content.description
            break;
        case "deployment":
            title = content.location.name
            body = content.description
            break;
        case "hardware":
            title = "hw change by " + user
            body = content.summary
            break;
        case "calibration":
            title = "calibration by " + user
            body = content.comment
            break;
        default:
            title = "oops"
            body = "oops"
    }

    return {type: type, title: title, body: body, id: id, time: time, user: user, elementID: content.id}
}


const mergeEvents = (data) => {
    var all = []
    data.comments.forEach((d,i) => {
        const { time, ...other } = d
        all.push({type: "comment", time: d.time, content: other})
    })
    data.deployment.forEach((d,i) => {
        const { time, ...other } = d
        all.push({type: "deployment", time: d.time, content: other})
    })
    data.stati.forEach((d,i) => {
        const { time, ...other } = d
        all.push({type: "status", time: d.time, content: other})
    })
    data.hardwarestatus.forEach((d,i) => {
        const { time, ...other } = d
        all.push({type: "hardware", time: d.time, content: other})
    })
    data.calibrations.forEach((d,i) => {
        const { opened, ...other } = d
        all.push({type: "calibration", time: opened, content: other})
    })
    data.issues.forEach((d,i) => {
        const { opened, closed, ...other } = d
        all.push({type: "issue_opened", time: opened, content: other})
        closed && all.push({type: "issue_closed", time: closed, content: other})
        d.actions.forEach((d,i) => {
            all.push({type: "issue_action", time: d.time, content: {description: d.description, category: other.issue.category.name, user: d.user}})
        })
    })

    all.sort((a,b) =>{
        return new Date(b.time) - new Date(a.time)
    })

    return all

}

const columns = [{
    name: 'type', 
    title: 'Type'
},
{
    name: 'title',
    title: 'Title'
},
{
    name: 'body',
    title: 'Body'
},
{
    name: 'time',
    title: 'Time'
},
{
    name: 'user',
    title: 'User'
}]

const tableColumnExtensions = [
    {columnName: 'type', width: 100},
    {columnName: 'title', width: 200},
    { columnName: 'body', wordWrapEnabled: true },
]

const TitleTypeFormatter = metaData => ({value, row}) => (row.type !== "comment" ? value : <CommentEditWidget {...metaData} data={row} />)

class Log extends React.PureComponent {



     TitleTypeProvider = props => (
      <DataTypeProvider
        formatterComponent={this.TitleTypeFormatter}
        {...props}
      />
    )


    render(){
        
        const all = this.props.interval ? mergeEvents(this.props.data).filter(x =>this.props.interval[0] < new Date(x.time) && this.props.interval[1] > new Date(x.time)).map((x,i) => logEntry(x, i)) : mergeEvents(this.props.data).map((x,i) => logEntry(x, i))

        //const all = mergeEvents(this.props.data).map((x,i) => logEntry(x, i))
        return(
            <Paper>
                <Grid rows={all} columns={columns} getRowId={ row => row.id } >
                   <LogTypeProvider for={['type']} />
                   <DataTypeProvider for={['title']} formatterComponent={TitleTypeFormatter({panID: this.props.data.panId, instrumentID: this.props.data.id, user: this.props.user})} />
                    <TextTypeProvider for={['body']} />
                    <TimeTypeProvider for={['time']} />
                    <SearchState defaultValue="" />
                    <IntegratedFiltering />
                    <Table columnExtensions={tableColumnExtensions} containerComponent={(props)=><Table.Container {...props} style={{overflow: "visible"}} />}/>
                    <TableHeaderRow />
                    <Toolbar />
                    <SearchPanel />
                </Grid>
            </Paper>
        )

    }
}

/*class Log extends React.PureComponent {


    render(){
        console.log(this.props.data)
        var comments = []
        this.props.data.forEach((d,i) => {
            comments.push(<TimelineEvent title={d.user}
                           createdAt={d.time}
                           icon={<Message />}
            >
            {d.text}
            </TimelineEvent>)
        })
        return(
            <Timeline>
            {comments}
            </Timeline>
        )

    }
}*/

export default Log;
