import { DJANGO_URL } from '../constants';
//import ApolloClient from "apollo-boost";
//import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { HAS_TOKEN } from "./queries/authentication";
import { MQTT } from './queries/mqtt';
//import gql from 'graphql-tag'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';

const httpLink = new HttpLink({ uri: DJANGO_URL + "graphql" });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem('JWT');
  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : '',
    }
  });

  return forward(operation);
})

const cache = new InMemoryCache({
  typePolicies: {
    /*    Query: {
          fields: {
            dataHeader: {
              keyArgs: ["id"],
              merge(existing, incoming, { mergeObjects }) {
                console.log(existing)
                console.log(incoming)
                return mergeObjects(existing, incoming)
    
              }
            }
          }
        },*/
    DataHeaderType: {
      fields: {
        /*        fileName: {
                  merge: false
                },
                content: {
                  merge: false
                },*/
        qflagCount: {
          keyArgs: false,
          merge(existing = [], incoming) {
            //console.log(existing)
            const ret = [...existing, ...incoming]
            //console.log(ret)
            return ret;
            //return incoming
          },
        },
      },
    },
  },
});




const client = new ApolloClient({
  //uri: DJANGO_URL + "graphql",
  cache: cache,
  link: concat(authMiddleware, httpLink),
  connectToDevTools: true,
  resolvers: {
    Query: {
      mqttForPan: (_root, _args, { cache }) => {

        const { mqtt } = cache.readQuery({ query: MQTT })

        //return mqtt[_args.panID]
        return mqtt[_args.panID]
      }
    },
    Mutation: {
      addMqttMessage: (_root, variables, { cache }) => {
        const message = JSON.parse(variables.message)
        const split = variables.topic.split('/')
        const panID = Number(split[3].substring(7))
        const spec = 's' + split[4]
        const type = split[5]
        const rCode = split.length > 6 ? split[6] : undefined

        //var { mqtt } = cache.readQuery({ query: GET_MQTT })
        var { mqtt } = cache.readQuery({ query: MQTT })
        let ret
        if (!(panID in mqtt)) {
          ret = {
            's1': { 'L2_LASTSEEN': {} },
            's2': { 'L2_LASTSEEN': {} }
          }
          if (rCode) {
            ret[spec][type][rCode] = message
          } else {
            ret[spec][type] = message
          }



        } else {
          ret = JSON.parse(JSON.stringify(mqtt[panID]))
          if (rCode) {
            ret[spec][type][rCode] = message
          } else {
            ret[spec][type] = message
          }


        }

        cache.writeQuery({ query: MQTT, data: { mqtt: { ...mqtt, [panID]: ret } } })

        return null;
      }
    }
  }
});


client.writeQuery({ query: MQTT, data: { mqtt: {} } })
client.writeQuery({ query: HAS_TOKEN, data: { hasToken: localStorage.getItem('JWT') } })


export default client; 
