import * as React from 'react';
import { scaleLinear, scaleTime, scaleUtc } from 'd3-scale'
import { extent } from 'd3-array'
import { select } from 'd3-selection'
import { line, curveBundle} from 'd3-shape'
import { axisTop, axisRight, axisLeft, axisBottom} from 'd3-axis'
import * as d3Zoom from 'd3-zoom'
import addDays from 'date-fns/addDays'
import { ISSUE_COLORS, STATUS_COLORS } from '../../constants'




//looking up "hw.cn" from "hw.cn.bla.bla.bla" in the ISSUE_COLORS dict
const getColor = (category) => {
    const split = category.split('.')
    const lookup = split.length < 2 ? category : split[0]+ "." + split[1]
    return ISSUE_COLORS[lookup]
}




const getIntervalsIssues = (data, earliestDay) => {

    var rows = {}

    for(var i=0;i<data.length ; i++){
        //if(data[i].closed && new Date(data[i].closed) < earliestDay){continue;}

        if(data[i].issue.category.name in rows){
            rows[data[i].issue.category.name].times.push([new Date([data[i].opened]), data[i].closed ? new Date(data[i].closed) : new Date() ])
        }else{
            rows[data[i].issue.category.name] = {value: data[i].issue.category.name, times: [[new Date([data[i].opened]), data[i].closed ? new Date(data[i].closed) : new Date() ]]}
        }
    }

    return Object.values(rows)
}

const getIntervalsStati = data => {

    var intervals = []

    for(var i = data.length-1; i >= 0; i--){
        if(i === 0){
            intervals.push({value: data[i].status.name, time: [new Date(data[i].time), new Date()]})
        }else{
            intervals.push({value: data[i].status.name, time: [new Date(data[i].time), new Date(data[i-1].time)]})
        }
    }
    return intervals
}

const getIntervalsLocation = data => {
    var intervals = []

    for(var i = data.length-1; i >= 0; i--){
        if(i === 0){
            intervals.push({value: data[i].location.name, time: [new Date(data[i].time), new Date()]})
        }else{
            intervals.push({value: data[i].location.name, time: [new Date(data[i].time), new Date(data[i-1].time)]})
        }
    }
    return intervals
}

const ROW_HEIGHT = 10
const STATUS_HEIGHT = 50
const LOCATION_HEIGHT = 50
const PADDING = 10
const LOC_COLORS = ['lightsteelblue', 'powderblue']

const SPECIAL_COLORS = {
    "LabIBK": STATUS_COLORS['laboratory'],
    "LabGSFC": STATUS_COLORS['laboratory'],
    "LabSciGlob": STATUS_COLORS['laboratory'],
    "Innsbruck": 'lightskyblue'
}

const MARGIN = {
    top: 5,
    bottom: 20,
    left: 5,
    right: 80
}

class SummaryTimeline extends React.PureComponent {

    constructor(props){
        super(props)
        this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
        this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom
    }

    componentDidMount(){
        this.createGraph()
    }

    createGraph = () => {

        var svg = select(this.node)

        const timeRange = [addDays(new Date(), -this.props.daysDelta), new Date()]
        var xScale = scaleUtc().range([0,this.WIDTH]).domain(timeRange)

        const statusData = this.props.data.stati ? getIntervalsStati(this.props.data.stati) : []
        const issueData = this.props.data.issues ? getIntervalsIssues(this.props.data.issues, timeRange[0]) : []
        const locationData = this.props.data.deployment ? getIntervalsLocation(this.props.data.deployment) : []


        this.ISSUE_HEIGHT = ROW_HEIGHT * issueData.length
        svg.attr("height", this.ISSUE_HEIGHT+ STATUS_HEIGHT + LOCATION_HEIGHT + 2*PADDING + MARGIN.top + MARGIN.bottom)

        var zoomX = d3Zoom.zoom().on("zoom", zoomedX)

        svg.call(zoomX)


        var statusGroup = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

        statusGroup.append("defs").append("clipPath")
                .attr("id", "clipStatus")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", this.WIDTH)
                .attr("height", STATUS_HEIGHT)

        const statusClip = statusGroup.append("g").attr("clip-path", "url(#clipStatus)")

        var statusRects = statusClip.selectAll(".rects").data(statusData).enter()
            .append("rect")
            .attr("fill", d => STATUS_COLORS[d.value])
            .attr("x", d=> xScale(d.time[0]))
            .attr("width", d=> xScale(d.time[1]) - xScale(d.time[0]))
            .attr("height", STATUS_HEIGHT)
        
        statusRects.append("title").text(d=> d.value)


        var issueGroup = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + (MARGIN.top + PADDING + STATUS_HEIGHT)+ ")")

        issueGroup.append("defs").append("clipPath")
                .attr("id", "clipIssue")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", this.WIDTH)
                .attr("height", this.ISSUE_HEIGHT)

        const issueClip = issueGroup.append("g").attr("clip-path", "url(#clipIssue)")
        var issueRows = issueClip.selectAll("g").data(issueData).enter()
            .append("g").attr("transform", (d,i) => ("translate(0,"+ i*ROW_HEIGHT + ")"))


        issueRows.each(function(p,i){
            var these = select(this).selectAll("rect").data(p=>p.times).enter()
            .append("rect").attr("fill", getColor(p.value))
            .attr("x", d => xScale(d[0]))
            .attr("width", d=> xScale(d[1]) - xScale(d[0]))
            .attr("y", 2).attr("height", ROW_HEIGHT-4)
            .append("title").text(p.value)
        })
        
        var categories = issueGroup.selectAll(".categories").data(issueData).enter()
            .append("g").attr("transform", (d,i) => ("translate(0,"+ i*ROW_HEIGHT + ")"))

        categories.append("text").attr("transform", "translate(" + (this.WIDTH + 5 )+ ",0)")
                .attr("text-anchor", "left")
                .attr("font-size", "10px")
                .attr("y", 8)
                .text(d=>d.value)

        var locationGroup = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + (MARGIN.top + 2*PADDING + STATUS_HEIGHT + this.ISSUE_HEIGHT) + ")")

        locationGroup.append("defs").append("clipPath")
                .attr("id", "clipLocation")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", this.WIDTH)
                .attr("height", LOCATION_HEIGHT)

        const locationClip = locationGroup.append("g").attr("clip-path", "url(#clipLocation)")

        var ctr = 0;
        var locationRects = locationClip.selectAll(".rects").data(locationData).enter()
            .append("rect")
            .attr("fill", (d,i) => {if(SPECIAL_COLORS[d.value]){ ctr = 0; return SPECIAL_COLORS[d.value] }else{ return LOC_COLORS[ctr++%2]} })
            .attr("x", d=> xScale(d.time[0]))
            .attr("width", d=> xScale(d.time[1]) - xScale(d.time[0]))
            .attr("height", LOCATION_HEIGHT)

        
        locationRects.append("title").text(d=> d.value)

        //call xaxis
        var xAxis = locationGroup.append("g")
            .attr("transform", "translate(0," + LOCATION_HEIGHT + ")")
            .call(axisBottom(xScale).tickSize(0))
            .call(g => g.select(".domain").remove())
            .style("font-size", "12px")

    
        var new_xScale = xScale;
        const WIDTH = this.WIDTH
        const handleZoom = this.props.handleZoom
        function zoomedX({transform}){
                new_xScale = transform.rescaleX(xScale)
                
                xAxis.call(axisBottom(new_xScale).tickSize(0)).call(g => g.select(".domain").remove())
                statusRects.attr("x", d=> new_xScale(d.time[0]))
                    .attr("width", d=> new_xScale(d.time[1]) - new_xScale(d.time[0]))

                issueRows.selectAll("rect").attr("x", d=> new_xScale(d[0]))
                    .attr("width", d=> new_xScale(d[1]) - new_xScale(d[0]))

                locationRects.attr("x", d=> new_xScale(d.time[0]))
                    .attr("width", d=> new_xScale(d.time[1]) - new_xScale(d.time[0]))

                handleZoom([new_xScale.invert(0), new_xScale.invert(WIDTH)])

            }

    }

    render(){

        

        return(
          <svg  ref={node => this.node = node} 
          width={this.props.size[0]} height={this.props.size[1]} />
          )
    }
}

export default SummaryTimeline;
 
 
