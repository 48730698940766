import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
/*import RaisedButton from 'material-ui/RaisedButton'*/
import TextField from '@material-ui/core/TextField';
import autoBind from 'react-autobind'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { HAS_TOKEN } from '../apollo/queries/authentication'

const styles = {
  root: {
    width: "30%",
    margin: "auto",
    padding: "15px 15px 15px 15px",
  },
};


class LoginForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            username:'',
            password:'',
        }
        autoBind(this)
    }

    onSubmit(event){
        event.preventDefault()
        this.props.login({variables:{username: this.state.username, password: this.state.password}, 
                          update: (client, { data: { tokenAuth } }) => {
                            if(tokenAuth.token){
                              client.writeQuery({query: HAS_TOKEN, data: {hasToken: tokenAuth.token}})
                              localStorage.setItem('JWT', tokenAuth.token)
                            }
                          },
                          onError: error => {console.log(error)}
                        })
    }

    render() {
/*      var errorAlert = undefined
      if("non_field_errors" in this.props.errors){
        errorAlert = <LoginSnackbar variant="error" 
          onClose={this.props.clearErrors} message={this.props.errors.non_field_errors[0]} />
      }*/
    return (
          <AppBar className={this.props.classes.root} position="static" color="default">
            <Toolbar>
              <Typography variant="h5" color="inherit">
                Login
              </Typography>
            </Toolbar>
           <TextField
           error={this.props.result.error}
             label="Username"
             margin="normal"
             onChange = {(event) => this.setState({username:event.target.value})}
             />
           <br/>
             <TextField
              error={this.props.result.error}
              helperText={this.props.result.error ? "wrong credentials" : null}
               type="password"
               label="Password"
               margin="normal"
               onChange = {(event) => {this.setState({password:event.target.value})}}
               onKeyDown={(event) => {if(event.key === "Enter"){this.onSubmit(event)}}}
               />
             <br/>
             {/*errorAlert ? errorAlert : null*/}
             <Button variant="contained" color="primary" onClick={(event) => this.onSubmit(event)}>Submit</Button>
             </AppBar>
    );
  }
}


export default withStyles(styles)(LoginForm);

 
