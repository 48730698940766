import { gql } from '@apollo/client'


export const UPDATE_CALIBRATION = gql`
  mutation UpdateCalibration($input: CalibrationUpdateGenericType!) {
    updateCalibration(newCalibration: $input){
        ok
        calibration {
              id
              opened
              closed
              comment
              data
              user {
                username
              }
        }
    }
  }
`;

export const CREATE_CALIBRATION = gql`
  mutation CreateCalibration($input: CalibrationCreateGenericType!) {
    createCalibration(newCalibration: $input){
        ok
        calibration {
              id
              opened
              closed
              comment
              data
              user {
                username
              }
        }
    }
  }
`;

export const DELETE_CALIBRATION = gql`
  mutation DeleteCalibration($input: ID!) {
    deleteCalibration(id: $input){
        ok
        calibration {
              id
              opened
              closed
              comment
              data
              user {
                username
              }
        }
    }
  }
`; 
