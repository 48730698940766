import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  //SortingState,
  //IntegratedSorting
} from '@devexpress/dx-react-grid';
import { CREATE_STATUS_ASSIGNMENT, UPDATE_STATUS_ASSIGNMENT, DELETE_STATUS_ASSIGNMENT } from '../../apollo/queries/status'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { StatusTypeProvider, TimeTypeProvider, TextTypeProvider, UserTypeProvider, CommandIcons} from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'

const columns = [
{
    name: 'status',
    title: 'Status'
},
{
    name: 'time',
    title: 'Time'
},
{
  name: 'description',
  title: 'Description'
},
{
  name: 'user',
  title: 'User',
  //getCellValue: row => row.user.username
}]

const tableColumnExtensions = [
    {columnName: 'status', width: 200},
    {columnName: 'time', width: 200},
    { columnName: 'description', wordWrapEnabled: true },
]

const flattenNesting = obj => (obj.status ? {...obj, status: obj.status.id} : obj)
/*
This flattenNesting function checks if the obj has a property named status.
If obj does have a status property, the function creates a new object that copies all properties
from obj and replaces the status property with the id property of the original status object.
If obj does not have a status property, it simply returns the original object without any modifications.

Example:

const originalObj = {
  name: "Example",
  status: {
    id: 1,
    description: "Active"
  }
};

const flattenedObj = flattenNesting(originalObj);

console.log(flattenedObj);
 Output: { name: "Example", status: 1 }
*/

class StatusTable extends React.PureComponent {

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props

        if(added){
          added.forEach(x => {

/*            this.props.create({
              variables: {input: {instrument: this.props.instrumentID, status: x.status.id, time: x.time, description: x.description}},
              refetchQueries: [{query: GET_INSTRUMENTS}]
            })*/
            const variables_post = {
                ...x,
                instrument: this.props.instrumentID,
                status: x.status.id,
                user: this.props.user.id
                }
            console.log("Creating status assignment:", variables_post);
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createStatusAssignment }}) => {
                if(!createStatusAssignment.ok){
                  console.log("Could not create status assignment, response: ",createStatusAssignment)
                  return}
                console.log("Created status assignment: ",createStatusAssignment)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].stati.unshift(createStatusAssignment.statusassignment)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }

        //Changed status assignments
        if(changed){
          //for each status assignment object
          data.forEach(x => {

            //if this specific status assignment object was changed (edited+saved)
            if(changed[x.id]){
                 console.log("Current status assginment object: ",x)
                 let variables_post = {
                   id: x.id, //Status assignment id
                   instrument: this.props.instrumentID,
                   status: x.status.id, //Status id
                   user: this.props.user.id,
                   time: x.time,
                   description: x.description
                   };

                 variables_post = {
                   ...variables_post,
                   ...flattenNesting(changed[x.id]) //depending on what was updated, this might update the time, description, or status
                   };

                 console.log("Changing status assignment:", variables_post);
                 this.props.update({
                    variables: {input: variables_post},
                    update: (store, {data: { updateStatusAssignment }}) => {
                      if(!updateStatusAssignment.ok){
                        console.log("Could not change status assignment, response: ",updateStatusAssignment)
                        return};
                      console.log("Status assignment changed: ", updateStatusAssignment)
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                      const statusIndex = cache.instruments[index].stati.findIndex(e => e.id === x.id)

                      cache.instruments[index].stati[statusIndex] = updateStatusAssignment.statusassignment
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})

                    }
                })
            }
            
          })
        }
        
        if(deleted){
          deleted.forEach(x => {
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteStatusAssignment }}) => {
                if(!deleteStatusAssignment.ok){return}
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const statusIndex = cache.instruments[index].stati.findIndex(e => e.id === x)
                cache.instruments[index].stati.splice(statusIndex,1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              },
              refetchQueries: [{query: GET_INSTRUMENTS}]
            })
          })
        }
    }



    render(){
    return(
        <Paper>
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
             <StatusTypeProvider
                for={['status']}
              />
            <TimeTypeProvider
                for={['time']}
              />
            <TextTypeProvider for={['description']} />
            <UserTypeProvider for={['user']} />
              <EditingState
              onCommitChanges={this.commitChanges}
              />
{/*              <SortingState
                defaultSorting={[{ columnName: 'time', direction: 'desc' }]}
            />
                <IntegratedSorting />*/}
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              /> 
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_STATUS_ASSIGNMENT, {name: 'create'}),
    graphql(UPDATE_STATUS_ASSIGNMENT, {name: 'update'}),
    graphql(DELETE_STATUS_ASSIGNMENT, {name: 'delete'})
    )(StatusTable) 
