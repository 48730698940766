import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import LoginForm from '../components/LoginForm'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { VERIFY_TOKEN, GET_USER, HAS_TOKEN, GET_TOKEN } from '../apollo/queries/authentication'
//import {login, clearErrors} from  '../store/auth/actions'
//import {authErrors, isAuthenticated} from '../store/auth/reducer'

const Login = (props) => {
  const { loading, error, data } = useQuery(HAS_TOKEN)

  const [login, result] = useMutation(GET_TOKEN)
  const [verify, ret] = useMutation(VERIFY_TOKEN)

  if(ret.data && ret.data.verifyToken){
    return (
      <Redirect to='/' />
    )
  }


  if( data.hasToken ){
    return <Verify token={data.hasToken} verify={verify} />
    //verify({variables:{token: data.hasToken }})
  }else{

    return <LoginForm login={login} result={result} {...props} />
  }

  return <p>{JSON.stringify(data)}</p>
/*  if(props.isAuthenticated) {
    return (
      <Redirect to='/' />
    )
  } else {
      return (<div>
        <LoginForm {...props}/>
        <p>{JSON.stringify(props.errors)}</p>
        </div>

      )
  }*/
}

function Verify(props){
    const { token, verify } = props
    //const [verify, ret] = useMutation(VERIFY_TOKEN)
    useEffect(() => { verify({variables:{token: token },
     update: (client, { data: { verifyToken } }) => {
                            if(verifyToken){
                              client.writeQuery({query: GET_USER, data: {user: verifyToken.payload.user}})
                            }
                          }}) }, [token, verify])
    
    return null
}

export default Login; 
