import { gql } from '@apollo/client'

export const GET_ISSUE_CATEGORIES = gql`
query {
    allIssueCategories {
        results {
            id
            name
        }
        totalCount
    }
}
`;

export const GET_ISSUES = gql`
query {
  allIssues {
    results {
      id
      description
      category {
        id
        name
      }
    }
    totalCount
  }
}
`

export const CREATE_ISSUE_CATEGORY = gql `
mutation CreateIssueCategory($input: IssueCategoryCreateGenericType!) {
  createIssueCategory(newIssuecategory: $input){
    ok
    issuecategory {
      id
      name
    }
  }
}
`

export const CREATE_ISSUE = gql `
mutation CreateIssue($input: IssueCreateGenericType!) {
  createIssue(newIssue: $input){
    ok
    issue {
      id
      description
      category {
        id
        name
      }
    }
  }
}
`




export const UPDATE_ISSUE_ASSIGNMENT = gql`
  mutation UpdateIssueAssignment($input: IssueAssignmentUpdateGenericType!) {
    updateIssueAssignment(newIssueassignment: $input){
        ok
        issueassignment {
              id
              opened
              closed
              issue {
                id
                description
                category {
                  id
                  name
                }
              }
              instrument {
                id
              }
              user {
                username
              }
              actions {
                id
                time
                description
                user {
                  username
                }
              }
        }
    }
  }
`;

export const CREATE_ISSUE_ASSIGNMENT = gql`
  mutation CreateIssueAssignment($input: IssueAssignmentCreateGenericType!) {
    createIssueAssignment(newIssueassignment: $input){
        ok
        issueassignment {
              id
              opened
              closed
              issue {
                id
                description
                category {
                  id
                  name
                }
              }
              instrument {
                id
              }
              user {
                username
              }
              actions {
                id
                time
                description
                user {
                  username
                }
              }
        }
    }
  }
`;

export const DELETE_ISSUE_ASSIGNMENT = gql`
  mutation DeleteIssueAssignment($input: ID!) {
    deleteIssueAssignment(id: $input){
        ok
        issueassignment {
              id
              opened
              closed
              issue {
                id
                description
                category {
                  id
                  name
                }
              }
              instrument {
                id
              }
              user {
                username
              }
              actions {
                id
                time
                description
                user {
                  username
                }
              }
        }
    }
  }
`;




export const UPDATE_ISSUE_ACTION = gql`
  mutation UpdateIssueAction($input: IssueActionUpdateGenericType!) {
    updateIssueAction(newIssueaction: $input){
        ok
        issueaction {
              id
              time
              description
              issueAssignment {
                id
              }
              user {
                username
              }
        }
    }
  }
`;

export const CREATE_ISSUE_ACTION = gql`
  mutation CreateIssueAction($input: IssueActionCreateGenericType!) {
    createIssueAction(newIssueaction: $input){
        ok
        issueaction {
              id
              time
              description
              issueAssignment {
                id
              }
              user {
                username
              }
        }
    }
  }
`;

export const DELETE_ISSUE_ACTION = gql`
  mutation DeleteIssueAction($input: ID!) {
    deleteIssueAction(id: $input){
        ok
        issueaction {
              id
              time
              description
              issueAssignment {
                id
              }
              user {
                username
              }
        }
    }
  }
`;
