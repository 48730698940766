import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  RowDetailState
} from '@devexpress/dx-react-grid';
import { CREATE_HARDWARE, UPDATE_HARDWARE_MUTATION, DELETE_HARDWARE } from '../../apollo/queries/hardware'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { TimeTypeProvider, TextTypeProvider, UserTypeProvider, CommandIcons} from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import HardwareForm from './utils/HardwareForm'






const HardwareFormatter = ({row}) => {
  var res = ""
  if(!row.data["instrument"]){
    return res
  }
  if(row.data["instrument"].model){
    res = res.concat(row.data["instrument"].model)
  }
  if(row.data["instrument.tracker"].model){
        res = res.concat(" - " + row.data["instrument.tracker"].model)
  }
  return(
  <span style={{whiteSpace: "pre-line"}} >{res}</span>)}


const HardwareTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={HardwareFormatter}
    {...props}
  />
)

const columns = [
{
    name: 'data',
    title: 'Data',
},
{
  name: 'summary',
  title: 'Summary',
},
{
    name: 'time',
    title: 'Time'
},
{
  name: 'user',
  title: 'User'
}
]

const tableColumnExtensions = [
    {columnName: 'data', width: 480},
]

const editingStateColumnExtension = [
    {columnName: 'data', editingEnabled: false},
]

//const flattenNesting = obj => (obj.status ? {...obj, status: obj.status.id} : obj)



class HardwareTable extends React.PureComponent {

    state = {expandedRow: undefined}

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props
        let newData = JSON.stringify({})
        if((data.length > 0) && data[0].data){
          newData = JSON.stringify(data[0].data)
        }

        if(added){
          added.forEach(x => {
            let variables_post = {
              instrument: this.props.instrumentID,
              ...x,
              data: newData,
              user: this.props.user.id,
            };
            console.log("Creating hardware status: ",variables_post);
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createHardwareStatus }}) => {
                if(!createHardwareStatus.ok){
                  console.log("Could not create hardware status, response: ",createHardwareStatus);
                  return};
                console.log("Hardware status created: ",createHardwareStatus);
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].hardwarestatus.unshift(createHardwareStatus.hardwarestatus)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }

        if(changed){
          data.forEach(x => {
            if(changed[x.id]){
                 console.log("Changing hardware status. Current object: ",x);
                 console.log("Changed: ",changed[x.id]);
                 let variables_post = {
                   id: x.id,
                   user: this.props.user.id,
                 }

                 variables_post = {
                   ...variables_post,
                   ...changed[x.id],
                 };

                 console.log("Changing hardware status to: ",variables_post);
                 this.props.update({
                    variables: {input: variables_post },
                    update: (store, {data: { updateHardwareStatusMutation }}) => {
                      if(!updateHardwareStatusMutation.ok){
                        console.log("Could not change hardware status, response: ",updateHardwareStatusMutation);
                        return};
                      console.log("Hardware status updated.");
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                      const hardwareIndex = cache.instruments[index].hardwarestatus.findIndex(e => e.id === x.id)

                      cache.instruments[index].hardwarestatus[hardwareIndex] = updateHardwareStatusMutation.hardwarestatus
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                    }
                })
            }
          })
        }
        
        if(deleted){
          deleted.forEach(x => {
            console.log("Deleting hardware status object: ",x)
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteHardwareStatus }}) => {
                if(!deleteHardwareStatus.ok){
                  console.log("Could not delete hardware status, response: ",deleteHardwareStatus)
                return};
                console.log("Hardware status deleted.")
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const hardwareIndex = cache.instruments[index].hardwarestatus.findIndex(e => e.id === x)
                cache.instruments[index].hardwarestatus.splice(hardwareIndex,1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }
    }

    RowDetail = ({row}) => (<HardwareForm data={row.data} instrumentID={this.props.instrumentID} statusID={row.id} user={this.props.user} />)

    handleRowClick = id => () => { this.state.expandedRow !== id ? this.setState({expandedRow: id}) : this.setState({expandedRow: undefined}) }
    Cell = ({column, row, ...restProps}) => (<Table.Cell {...restProps} onClick={this.handleRowClick(row.id)} style={{ cursor: 'pointer'}} />)
    ToggleComponent = (props) => (<Table.StubCell {...props} />)


    render(){
    return(
        <Paper>
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
            <HardwareTypeProvider for={['data']} />
            <TextTypeProvider for={['summary']} />
            <TimeTypeProvider
                for={['time']}
              />
            <UserTypeProvider for={['user']} />

              <EditingState
              onCommitChanges={this.commitChanges}
              columnExtensions={editingStateColumnExtension}
              />
              <RowDetailState expandedRowIds={this.state.expandedRow ? [this.state.expandedRow] : []} />
{/*              <SortingState
                defaultSorting={[{ columnName: 'time', direction: 'desc' }]}
            />
                <IntegratedSorting />*/}
              <Table columnExtensions={tableColumnExtensions} cellComponent={this.Cell} />
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              />
              <TableRowDetail 
              contentComponent={this.RowDetail}
              toggleCellComponent={this.ToggleComponent}
                toggleColumnWidth={0}
               />
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_HARDWARE, {name: 'create'}),
    graphql(UPDATE_HARDWARE_MUTATION, {name:'update'}),
    graphql(DELETE_HARDWARE, {name: 'delete'})
    )(HardwareTable) 
 
