import React from 'react';

import {
  Template, TemplatePlaceholder, Plugin, TemplateConnector,
} from '@devexpress/dx-react-core';


import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



export default function FilterToggle(props){
  const { toggleFilter, filtered } = props;

  return (
    <Plugin name="FilterToggle">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {({}) => (
            <React.Fragment>
              <Tooltip title='Toggle pgn filter' placement='bottom'>
              <FormControlLabel control={
                <Checkbox value={filtered} onChange={toggleFilter}/>
              }
              label="pgn only" />
              </Tooltip>

            </React.Fragment>
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  );
}