import React from 'react';
//import { connect } from 'react-redux'
//import * as ilbSelectors from '../../../store/ilb/reducer'
import { select} from 'd3-selection'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import { STATUS_COLORS } from '../../../constants'
import { useQuery } from '@apollo/react-hooks'
import { MQTT_FOR_PAN } from '../../../apollo/queries/mqtt'


const MARGIN = {
    top: 15,
    bottom: 10,
    left: 10,
    right: 5
}

const SQUARE_SIZE = 15

const getColor = (timeDiff) => {
    const seconds = timeDiff / 1000
    if(seconds < 1800){return STATUS_COLORS['operational']}
    else if(seconds < 86400){ return STATUS_COLORS['hold due to issue']}
    else{ return STATUS_COLORS['out of operation']}
}

const getTimeString = (timeDiff) => {
    const seconds = timeDiff / 1000
    if(seconds < 60){ return "<1m" }
    else if(seconds < 3600){ return Math.round(seconds/60) + "m"}
    else if(seconds < 3600*24){ return Math.round(seconds/3600) + "h"}
    else { return Math.round(seconds/(3600*24)) + "d"}
}



class MqttTest extends React.Component {

    constructor(props){
        super(props)
        this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
        this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom
    }



    componentDidMount(){
        this.interval = setInterval(() => {
            select(this.node).selectAll("g").remove()
            this.createGraph()
        }, 20000);
        this.createGraph()
    }


/*    componentDidUpdate(){
        if(!this.state.scheduledUpdate){return}
        console.log("updating " + this.props.panId)
        select(this.node).selectAll("g").remove()
        this.createGraph()
        this.setState({scheduledUpdate: false})
    }*/

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    shouldComponentUpdate(nextState, nextProps){
        return false;
    }



    createGraph(){

        if(!this.props.mqtt){ return; }


        const data_L0_s1 = this.props.mqtt.s1.L0_LASTSEEN ? new Date() - new Date(this.props.mqtt.s1.L0_LASTSEEN.time*1000) : undefined
        const rCodes_s1 = Object.values(this.props.mqtt.s1.L2_LASTSEEN)
        rCodes_s1.sort((a,b) => (b.time - a.time))

        const data_L2_s1 = rCodes_s1.length > 0 ? new Date() - new Date(rCodes_s1[0].time*1000) : undefined

        const data_L0_s2 = this.props.mqtt.s2.L0_LASTSEEN ? new Date() - new Date(this.props.mqtt.s2.L0_LASTSEEN.time*1000) : undefined
        const rCodes_s2 = Object.values(this.props.mqtt.s2.L2_LASTSEEN)
        rCodes_s2.sort((a,b) => (b.time - a.time))

        const data_L2_s2 = rCodes_s2.length > 0 ? new Date() - new Date(rCodes_s2[0].time*1000) : undefined




        var svg = select(this.node)
        var group_s1 = svg.append("g")
        var group_L0_s1 = group_s1.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

        var group_L2_s1 = group_s1.append("g")
                    .attr("transform", "translate(" + ((MARGIN.left*2) + SQUARE_SIZE) +  "," + MARGIN.top + ")")




        if(data_L0_s1){
            group_L0_s1.append("rect").attr("height", SQUARE_SIZE).attr("width", SQUARE_SIZE)
                                .attr("fill", getColor(data_L0_s1))

            group_L0_s1.append("text")
                    .attr("font-size", "10px")
                    .attr("y", SQUARE_SIZE + MARGIN.bottom)
                    .text(getTimeString(data_L0_s1))

            group_L0_s1.append("text")
                    .attr("font-size", "10px").attr("y", -3)
                    .text("L0")

            group_L0_s1.append("text")
                    .attr("font-size", "10px").attr("y", SQUARE_SIZE*.75).attr("x", -11)
                    .text("s1")
        }
        if(data_L2_s1){

            group_L2_s1.append("rect").attr("height", SQUARE_SIZE).attr("width", SQUARE_SIZE)
                                .attr("fill", getColor(data_L2_s1))

            group_L2_s1.append("text")
                    .attr("font-size", "10px")
                    .attr("y", SQUARE_SIZE + MARGIN.bottom)
                    .text(getTimeString(data_L2_s1))

            group_L2_s1.append("text")
                    .attr("font-size", "10px").attr("y", -3)
                    .text("L2")
        }

        var group_s2 = svg.append("g").attr("transform", "translate(" + (MARGIN.left*3 + 2*SQUARE_SIZE) + ",0)")
        var group_L0_s2 = group_s2.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

        var group_L2_s2 = group_s2.append("g")
                    .attr("transform", "translate(" + ((MARGIN.left*2) + SQUARE_SIZE) +  "," + MARGIN.top + ")")

        if(data_L0_s2){
            group_L0_s2.append("rect").attr("height", SQUARE_SIZE).attr("width", SQUARE_SIZE)
                                .attr("fill", getColor(data_L0_s2))

            group_L0_s2.append("text")
                    .attr("font-size", "10px")
                    .attr("y", SQUARE_SIZE + MARGIN.bottom)
                    .text(getTimeString(data_L0_s2))

            group_L0_s2.append("text")
                    .attr("font-size", "10px").attr("y", -3)
                    .text("L0")

            group_L0_s2.append("text")
                    .attr("font-size", "10px").attr("y", SQUARE_SIZE*.75).attr("x", -11)
                    .text("s2")
        }
        if(data_L2_s2){

            group_L2_s2.append("rect").attr("height", SQUARE_SIZE).attr("width", SQUARE_SIZE)
                                .attr("fill", getColor(data_L2_s2))

            group_L2_s2.append("text")
                    .attr("font-size", "10px")
                    .attr("y", SQUARE_SIZE + MARGIN.bottom)
                    .text(getTimeString(data_L2_s2))

            group_L2_s2.append("text")
                    .attr("font-size", "10px").attr("y", -3)
                    .text("L2")
        }

    }


    render(){
        return(
          <svg ref={node => this.node = node} 
          width={this.props.size[0]} height={this.props.size[1]} />
          )
    }
}

/*function MqttWrapper(props){
    const { data } = useQuery(MQTT_FOR_PAN, {variables: {panID: props.panId}})
    return (data ? <MqttTest {...props} mqtt={data.mqttForPan} /> : null )
}*/

export default MqttTest
 
