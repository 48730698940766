import * as React from 'react';
import {
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import EventIcon from '@material-ui/icons/Event'
import DateRange from '@material-ui/icons/DateRange'
import AccessTime from '@material-ui/icons/AccessTime'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import Save from '@material-ui/icons/Save'
import Cancel from '@material-ui/icons/Cancel'
import { GET_LOCATIONS } from '../../../apollo/queries/locations'
import { GET_STATI } from '../../../apollo/queries/status'
import { GET_ISSUES, GET_ISSUE_CATEGORIES} from '../../../apollo/queries/issue'
import { Query } from 'react-apollo'
import * as moment from 'moment'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import isDate from 'date-fns/isDate'
import MaskedInput from 'react-text-mask'
import SelectField from 'material-ui-superselectfield'
import IssuePopup from './IssuePopup'
import IssueCategoryPopup from './IssueCategoryPopup'
import DeleteDialog from './DeleteDialog'
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter'


const styles = theme => ({
  root: {
    display: 'flex',
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  autocomplete: {

  }
});


const LocationFormatter = ({ value }) => (value && value.name)
const LocationEditor = ({ value, onValueChange }) => {
return (
<Query query={GET_LOCATIONS} >
{({loading, error, data}) => {
   if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    return (
/*      <Select
        input={<Input />}
        value={value ? value.name : "select location"}
        onChange={event => onValueChange(data.allLocations.results.find(x=> x.name === event.target.value))}
        style={{ width: '100%' }}
      >
      {data.allLocations.results.map(x => (
            <MenuItem key={x.id} value={x.name}>
                {x.name}
            </MenuItem>

        ))}
      </Select>*/

      <SelectField
      name="location"
      showAutocompleteThreshold='always'
      selectedMenuItemStyle={{color: "blue"}}
      value={value && {value: value.name}}
      onChange={(values, name) => { values && Object.keys(values).length < 2 ? onValueChange(null) :
          onValueChange(data.allLocations.results.find(x=> values ? x.name === values.value : false)) }}
      style={{ width: '100%' }}
      >
      {data.allLocations.results.map(x => (            
            <div key={x.name} value={x.name}>
                {x.name}
            </div>

        ))}
      </SelectField>


    )
}}
</Query>
)
}

export const LocationTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={LocationFormatter}
    editorComponent={LocationEditor}
    {...props}
  />
)

const StatusFormatter = ({ value }) => (value && value.name)
const StatusEditor = ({ value, onValueChange }) => {

return (
<Query query={GET_STATI} >
{({loading, error, data}) => {
   if (loading) return <p>Loading...</p>;
    if (error) return <p>Error from Stati</p>;
    return (
      <Select
        input={<Input />}
        value={value ? value.name : "select status"}
        onChange={event => onValueChange(data.allStati.results.find(x=> x.name === event.target.value))}
        style={{ width: '100%' }}
      >
      {data.allStati.results.map(x => (
            <MenuItem key={x.id} value={x.name}>
                {x.name}
            </MenuItem>

        ))}
      </Select>
    )

}}
</Query>
)
}

export const StatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={StatusFormatter}
    editorComponent={StatusEditor}
    {...props}
  />
)

const IssueCategoryFormatter = ({ value }) => (value && value.name)

const IssueCategoryEditor = ({ value, onValueChange, row}) => {
return (
<Query query={GET_ISSUE_CATEGORIES} >
{({loading, error, data}) => {
   if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
      <div style={{display: 'flex'}}>
      <SelectField
      name="issue"
      showAutocompleteThreshold='always'
      withResetSelectAllButtons
      selectedMenuItemStyle={{color: "blue"}}
      value={value && {value: value.name}}
      onChange={(values, name) =>{ values && Object.keys(values).length < 2 ? onValueChange(null) :
                     onValueChange(data.allIssueCategories.results.find(x => values ? x.name === values.value : false)) }}
      style={{ width: '100%' }}
      >
      {data.allIssueCategories.results.map(x => (
            <div key={x.id} value={x.name}>
                {x.name}
            </div>

        ))}
      </SelectField>
      {/*<IssueCategoryPopup />*/}
      </div>)
}}
</Query>
)
}

export const IssueCategoryTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={IssueCategoryFormatter}
    editorComponent={IssueCategoryEditor}
    {...props}
  />
)


const DescriptionFormatter = ({ value }) => (value && <span style={{whiteSpace: "pre-line"}} >{value.description}</span>)

/*const DescriptionEditor = ({value, onValueChange, row}) => {

  return (
<Query query={GET_ISSUES}>
{({loading, error, data}) => {
   if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    const allIssues = value && value.category ? data.allIssues.results.filter(x => x.category.id === value.category.id) : data.allIssues.results

    return (
      <div style={{display: 'flex'}}>
      <SelectField
      name="issue_description"
      showAutocompleteThreshold='always'
      selectedMenuItemStyle={{color: "blue"}}
      value={value && {value: value.description}}
      onChange={(values, name) => { values && Object.keys(values).length < 2 ? onValueChange(null) :
          onValueChange(allIssues.find(x=> values ? x.description === values.value : false)) }}
      style={{ width: '100%' }}
      >
      {allIssues.map(x => (            
            <div key={x.description} value={x.description}>
                <Typography color="primary" variant="subtitle2" >{x.category.name}</Typography>
                <Typography variant="caption" >{x.description}</Typography>
            </div>

        ))}
      </SelectField>
      <IssuePopup category={value && value.category} />
      </div>    )
}}
</Query>
)
}*/

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, {keys:['description', 'category.name']});


const DescriptionEditor = ({value, onValueChange, row}) => {

  return (
<Query query={GET_ISSUES}>
{({loading, error, data}) => {
   if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;


    const allIssues = value && value.category ? data.allIssues.results.filter(x => x.category.id === value.category.id) : data.allIssues.results

    return (
      <div style={{display: 'flex'}}>
      <Autocomplete
        id="issue_autocomplete"
        options={allIssues}
        getOptionLabel={(option) => option.description + ' \n' + option.category.name}
        renderOption={(option, state) => (<div key={option.description}>
                <Typography color="primary" variant="subtitle2" >{option.category.name}</Typography>
                <Typography variant="caption" >{option.description}</Typography>
            </div>) }
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} multiline />}
        value={value}
        onChange={(event, selectedValue, reason) => {
            onValueChange(selectedValue)
        }}

      />
      {/*<IssuePopup category={value && value.category} />*/}
      </div>    )
}}
</Query>
)
}



export const IssueDescriptionTypeProvider = props => (
  <DataTypeProvider
  formatterComponent={DescriptionFormatter}
  editorComponent={DescriptionEditor} {...props} />
  )


export const TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss'
//const TIME_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/,':',/\d/,/\d/,':',/\d/, /\d/]
export const TIME_MASK = [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, ' ', /\d/, /\d/,':',/\d/,/\d/,':',/\d/, /\d/, ' UTC']


//const TimeFormatter = ({ value }) => {return (value && format(parseISO(value), TIME_FORMAT))}
const TimeFormatter = ({ value }) => {return (value && moment.utc(value).format(TIME_FORMAT) + " UTC")}

const TimeEditor = ({ value, onValueChange }) => {
      const toRender = value ?  
      <MaskedInput
        mask={TIME_MASK}
        showMask
        keepCharPositions
        defaultValue={/*isDate(value) && isValid(value) ? format(value, TIME_FORMAT) : isValid(parseISO(value)) ? format(parseISO(value), TIME_FORMAT) : value */
          moment.utc(value).format(TIME_FORMAT)}
        onChange={event => { onValueChange(moment.utc(event.target.value, TIME_FORMAT)) } }
      />
      : <Button onClick={ () => (onValueChange(moment.utc()))}>Now</Button>
      return (toRender)
}

/*const TimeEditor = ({ value, onValueChange }) => {
return (
    <InlineDateTimePicker
          value={value ? value : new Date()}
          ampm={false}
          keyboard
          leftArrowIcon={<ArrowLeft />}
          rightArrowIcon={<ArrowRight />}
          keyboardIcon={<EventIcon />}
          timeIcon={<AccessTime />}
          dateRangeIcon={<DateRange />}
          format="YYYY/MM/dd HH:mm:ss"
          showTodayButton

          onChange={date => {console.log('editor: ' + date.toISOString()); onValueChange(date.toISOString())}}
    />
)
}*/

export const TimeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={TimeFormatter}
    editorComponent={TimeEditor}
    {...props}
  />
)

const GetIcon = id => {
  switch(id){
    case "add":
      return <Add color="primary" />
    case "edit":
      return <Edit color="primary" />
    case "delete":
      return <Delete color="disabled" />
    case "commit":
      return <Save color="primary" />
    case "cancel":
      return <Cancel color="error" />
    default:
      return <EventIcon />
  }
}

const TextFormatter = ({value}) => {
  return (<span style={{whiteSpace: "pre-line"}} >{value}</span>)
}

const TextEditor = ({value, onValueChange}) => {
  return(<TextField multiline fullWidth onChange={event => onValueChange(event.target.value)} 
          defaultValue={value && value} />)
}

export const TextTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={TextFormatter}
    editorComponent={TextEditor}
    {...props}
  />
)

const UserFormatter = ({value}) => (value && value.username)

const UserEditor = ({value, onValueChange}) => (null)

export const UserTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={UserFormatter}
    editorComponent={UserEditor}
    {...props}
  />
)


export const CommandIcons = props => {
  const {onExecute, ...other} = props
  if(props.id === "delete"){
    return(<DeleteDialog {...other} onExecute={onExecute} icon={GetIcon(props.id)} />)
  }
  return (<Button {...other} onClick={onExecute}>{GetIcon(props.id)}</Button>)
}


