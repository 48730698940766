import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom'
import './index.css';
import ILB from './containers/ILB';
import Login from './containers/Login'
import AllDataPlot from './containers/apollo/AllData'
import PrivateRoute from './containers/PrivateRoute'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProviderOld from 'material-ui/styles/MuiThemeProvider'
import * as serviceWorker from './serviceWorker';
import ApolloClient from './apollo/client'
import { ApolloProvider as LegacyProvider } from "react-apollo";
import { ApolloProvider } from '@apollo/client';
import DataExplorer from './containers/apollo/DataExplorer'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1561D1' },
    secondary: { main: '#8F8DC0' },
  },
});

ReactDOM.render(
	<Router>
		<ApolloProvider client={ApolloClient} >
    <LegacyProvider client={ApolloClient} >
	  <MuiThemeProvider theme={theme}>
      <MuiThemeProviderOld theme={theme}>
      <Switch>
        <PrivateRoute exact path="/" component={ILB}/>
{/*        <Route exact path="/explorer" component={DataExplorer}/>*/}
        <Route exact path="/login" component={Login} />
{/*        <Route exact path="/days" component={AllDataPlot} />*/}
      </Switch>
     </MuiThemeProviderOld>
     </MuiThemeProvider>
     </LegacyProvider>
     </ApolloProvider>
	</Router>, 
	document.getElementById('root')
	);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
