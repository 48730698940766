import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import { CREATE_DEPLOYMENT, UPDATE_DEPLOYMENT, DELETE_DEPLOYMENT } from '../../apollo/queries/deployments'
import { CREATE_STATUS_ASSIGNMENT } from '../../apollo/queries/status'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { LocationTypeProvider, TimeTypeProvider, TextTypeProvider, UserTypeProvider, CommandIcons} from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { DJANGO_URL } from '../../constants'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';


function StatusDialog(props) {

    const { onClose, ...other } = props

    function handleYes(){
        onClose(true)
    }

    function handleNo(){
        onClose(false)
    }

    return(
      <Dialog onClose={handleNo} {...other} >
        <DialogTitle>Auto-Create a status assignment with 'laboratory'?</DialogTitle>
        <Button onClick={handleYes}>Yes</Button>
        <Button onClick={handleNo}>No</Button>
      </Dialog>
    )

}

const columns = [
{
    name: 'location',
    title: <a href={DJANGO_URL + "admin/stations/location/"} target="_blank" >Location</a>,
},
{
    name: 'time',
    title: 'Time'
},
{
  name: 'description',
  title: 'Description'
},
{
  name: 'user',
  title: 'User'
}]

const tableColumnExtensions = [
    {columnName: 'location', width: 200},
    {columnName: 'time', width: 200},
    { columnName: 'description', wordWrapEnabled: true },
]

const flattenNesting = obj => (obj.location ? {...obj, location: obj.location.id} : obj)


class DeploymentTable extends React.PureComponent {

    state = {open: false, data: undefined }

    handleTrigger = yes => {
      if(yes){
        const description = "automatically created due to location change: " + this.state.data.location.name
        this.props.createStatus({
          variables: {input: {instrument: this.props.instrumentID, status: 4, user: this.props.user.id, description: description, time: this.state.data.time}},
          update: (store, {data: { createStatusAssignment }}) => {
                if(!createStatusAssignment.ok){
                  console.log("Could not create status assignment, response: ", createStatusAssignment)
                  return};
                console.log("Status assignment created: ",createStatusAssignment)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].stati.unshift(createStatusAssignment.statusassignment)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
        })
      }
      this.setState({open: false, data: undefined})
    }

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props

        if(added){
          added.forEach(x => {
            let variables_post = {
              ...x,
              instrument: this.props.instrumentID,
              location: x.location.id,
              user: this.props.user.id,
            };
            console.log("Creating new deployment: ",variables_post);
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createDeployment }}) => {
                if(!createDeployment.ok){
                  console.log("Could not create deployment, response: ",createDeployment);
                  return};
                console.log("Created new deployment: ",createDeployment)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].deployment.unshift(createDeployment.deployment)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                if(x.location.name.startsWith("Lab")){
                  this.setState({open: true, data: x})
                }
              }
            })
          })
        }

        if(changed){
          data.forEach(x => {
            if(changed[x.id]){
                 console.log("Current deployment object: ",x)
                 console.log("Changed: ",changed[x.id])
                 let variables_post = {
                   id: x.id,
                   instrument: this.props.instrumentID,
                   location: x.location.id,
                   user: this.props.user.id,
                 };

                 variables_post = {
                   ...variables_post,
                   ...flattenNesting(changed[x.id]),
                 };

                 console.log("Updating deployment: ",variables_post);
                 this.props.update({
                    variables: {input: variables_post },
                    update: (store, {data: { updateDeployment }}) => {
                      if(!updateDeployment.ok){
                        console.log('Could not update deployment, response:', updateDeployment);
                        return};
                      console.log("Deployment updated", updateDeployment)
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                      const deploymentIndex = cache.instruments[index].deployment.findIndex(e => e.id === x.id)

                      cache.instruments[index].deployment[deploymentIndex] = updateDeployment.deployment
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                    }
                })
            }
          })
        }
        
        if(deleted){
          deleted.forEach(x => {
            console.log("Deleting deployment object: ",x)
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteDeployment }}) => {
                if(!deleteDeployment.ok){return}
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const deploymentIndex = cache.instruments[index].deployment.findIndex(e => e.id === x)
                cache.instruments[index].deployment.splice(deploymentIndex,1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }
    }



    render(){
    return(
        <Paper>
            <StatusDialog open={this.state.open} onClose={this.handleTrigger} />
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
             <LocationTypeProvider
                for={['location']}
              />
            <TimeTypeProvider
                for={['time']}
              />
            <TextTypeProvider for={['description']} />
            <UserTypeProvider for={['user']} />
              <EditingState
              onCommitChanges={this.commitChanges}
              />
{/*              <SortingState
                defaultSorting={[{ columnName: 'time', direction: 'desc' }]}
            />
                <IntegratedSorting />*/}
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              /> 
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_DEPLOYMENT, {name: 'create'}),
    graphql(UPDATE_DEPLOYMENT, {name: 'update'}),
    graphql(DELETE_DEPLOYMENT, {name: 'delete'}),
    graphql(CREATE_STATUS_ASSIGNMENT, {name: 'createStatus'})
    )(DeploymentTable)