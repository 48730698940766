import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { CREATE_CALIBRATION, UPDATE_CALIBRATION, DELETE_CALIBRATION } from '../../apollo/queries/calibrations'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { StatusTypeProvider, TimeTypeProvider, TextTypeProvider, UserTypeProvider, CommandIcons} from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import CalibrationViz from './utils/CalibrationViz'


const INIT = {"measured": {"L0": false, "L1": false, "L2": false, "L3": false, "L4": false,
                                    "L5": false, "L6": false,"L7": false, "L8": false, "LT": false},
            "verified": {"L0": false, "L1": false, "L2": false, "L3": false, "L4": false,
                                    "L5": false, "L6": false,"L7": false, "L8": false, "LT": false},
            "measured_s2": {"L0": false, "L1": false, "L2": false, "L3": false, "L4": false,
                                    "L5": false, "L6": false,"L7": false, "L8": false, "LT": false},
            "verified_s2":  {"L0": false, "L1": false, "L2": false, "L3": false, "L4": false,
                                    "L5": false, "L6": false,"L7": false, "L8": false, "LT": false}}



class CalibrationWidget extends React.PureComponent {


    handleClick = type => l => {
        const json = this.props.data ? JSON.parse(this.props.data) : INIT
        const data = json.hasOwnProperty('measured_s2') ? json : {...INIT, ...json }
        var toChange = {...data[type]}
        toChange[l] = !toChange[l]
        this.props.onChange(JSON.stringify({...data, [type]: toChange}))
    }

    render(){

        const json = this.props.data ? JSON.parse(this.props.data) : INIT
        const data = json.hasOwnProperty('measured_s2') ? json : {...INIT, ...json }
        return(<CalibrationViz data={data} handleClick={this.handleClick} />)
    }


}

const CalibrationFormatter = ({row}) => (<CalibrationViz data={JSON.parse(row.data)} />)

const CalibrationEditor = ({value, onValueChange}) => (<CalibrationWidget data={value} onChange={onValueChange} />)

const CalibrationTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={CalibrationFormatter}
    editorComponent={CalibrationEditor}
    {...props}
  />
)

const columns = [
{
    name: 'data',
    title: 'Data'
},
{
    name: 'comment',
    title: 'Comment'
},
{
    name: 'opened',
    title: 'Opened'
},
{
    name: 'closed',
    title: 'Closed'
},
{
  name: 'user',
  title: 'User'
}
]

const tableColumnExtensions = [
    {columnName: 'data', width: 480},
    { columnName: 'comment', wordWrapEnabled: true },
]

//const flattenNesting = obj => (obj.status ? {...obj, status: obj.status.id} : obj)


class CalibrationTable extends React.PureComponent {

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props

        if(added){
          added.forEach(x => {

            let variables_post = {
              instrument: this.props.instrumentID,
              ...x,
              user: this.props.user.id
            };
            console.log("Creating calibration: ",variables_post)
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createCalibration }}) => {
                if(!createCalibration.ok){
                  console.log("Could not create calibration, response: ",createCalibration);
                  return};
                console.log("Calibration created: ", createCalibration)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].calibrations.unshift(createCalibration.calibration)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }

        if(changed){
          data.forEach(x => {
            if(changed[x.id]){
                 console.log("Current calibration object: ",x)
                 console.log("Changed: ",changed[x.id])
                 let variables_post = {
                   id: x.id,
                   instrument: this.props.instrumentID,
                   user: this.props.user.id
                 };

                 variables_post = {
                   ...variables_post,
                   ...changed[x.id],
                 };
                 console.log("Updating calibration: ",variables_post);
                 this.props.update({
                    variables: {input: variables_post },
                    update: (store, {data: { updateCalibration }}) => {
                      if(!updateCalibration.ok){
                        console.log("Could not update calibration, response: ", updateCalibration)
                        return};
                      console.log("Calibration updated: ", updateCalibration)
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                      const calibrationIndex = cache.instruments[index].calibrations.findIndex(e => e.id === x.id)

                      cache.instruments[index].calibrations[calibrationIndex] = updateCalibration.calibration
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                    }
                })
            }
          })
        }
        
        if(deleted){
          deleted.forEach(x => {
            console.log("Deleting calibration object: ",x)
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteCalibration }}) => {
                if(!deleteCalibration.ok){
                  console.log("Could not delete calibration, response: ", deleteCalibration)
                  return};
                console.log("Calibration object deleted")
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const calibrationIndex = cache.instruments[index].calibrations.findIndex(e => e.id === x)
                cache.instruments[index].calibrations.splice(calibrationIndex,1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }
    }



    render(){
    return(
        <Paper>
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
            <CalibrationTypeProvider for={['data']} />
            <TextTypeProvider for={['comment']} />
            <TimeTypeProvider
                for={['opened','closed']}
              />
            <UserTypeProvider for={['user']} />

              <EditingState
              onCommitChanges={this.commitChanges}
              />
{/*              <SortingState
                defaultSorting={[{ columnName: 'time', direction: 'desc' }]}
            />
                <IntegratedSorting />*/}
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              /> 
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_CALIBRATION, {name: 'create'}),
    graphql(UPDATE_CALIBRATION, {name: 'update'}),
    graphql(DELETE_CALIBRATION, {name: 'delete'})
    )(CalibrationTable) 
 
