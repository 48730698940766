import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import { CREATE_ISSUE_ACTION, UPDATE_ISSUE_ACTION, DELETE_ISSUE_ACTION } from '../../apollo/queries/issue'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { TimeTypeProvider, TextTypeProvider, UserTypeProvider, CommandIcons } from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'

const columns = [
{
    name: 'description',
    title: 'Description'
},
{
    name: 'time',
    title: 'Time'
},
{
  name: 'user',
  title: 'User'
}]

const tableColumnExtensions = [
    { columnName: 'description', wordWrapEnabled: true },
]


class ActionTable extends React.PureComponent {

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props

        if(added){
          added.forEach(x => {
            let variables_post = {
            issueAssignment: this.props.issueAssignmentID,
            description: x.description,
            time: x.time,
            user: this.props.user.id
            };
            console.log("Creating issue action: ",variables_post)
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createIssueAction }}) => {
                if(!createIssueAction.ok){
                  console.log("Could not create issue action, response: ",createIssueAction);
                  return};
                console.log("Created issue action: ",createIssueAction)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                //console.log("Cache: ",cache)
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const issueIndex = cache.instruments[index].issues.findIndex(e => e.id === this.props.issueAssignmentID)
                cache.instruments[index].issues[issueIndex].actions.unshift(createIssueAction.issueaction)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                //console.log("Cache: ",cache)
              }
            })
          })
        }

        if(changed){
          data.forEach(x => {
            if(changed[x.id]){
                 console.log("Current issue action object: ",x)
                 console.log("Changed: ",changed[x.id])
                 let variables_post = {
                   id: x.id,
                   issueAssignment: this.props.issueAssignmentID,
                   description: x.description,
                   time: x.time,
                   user: this.props.user.id,
                 };

                 variables_post = {
                   ...variables_post,
                   ...changed[x.id],
                 };

                 console.log("Updating issue action: ",variables_post);
                 this.props.update({
                    variables: {input: variables_post },
                    update: (store, {data: { updateIssueAction }}) => {
                      if(!updateIssueAction.ok){
                        console.log('Could not update issue action, response:', updateIssueAction);
                        return};
                      console.log("Issue action updated", updateIssueAction)
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      //console.log("Cache: ",cache)
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                      const issueIndex = cache.instruments[index].issues.findIndex(e => e.id === this.props.issueAssignmentID)
                      const actionIndex = cache.instruments[index].issues[issueIndex].actions.findIndex(e => e.id === updateIssueAction.issueaction.id)
                      //console.log("Instrument index", index)
                      //console.log("IssueAssignment index", issueIndex)
                      //console.log("Action index", actionIndex)
                      cache.instruments[index].issues[issueIndex].actions[actionIndex] = updateIssueAction.issueaction
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                      //console.log("Cache: ",cache)
                    }
                })
            }
          })
        }
        
        if(deleted){
          deleted.forEach(x => {
            console.log("Deleting issue action object: ",x)
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteIssueAction }}) => {
                if(!deleteIssueAction.ok){return}
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                //console.log("Cache: ",cache)
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const issueIndex = cache.instruments[index].issues.findIndex(e => e.id === this.props.issueAssignmentID)
                const actionIndex = cache.instruments[index].issues[issueIndex].actions.findIndex(e => e.id === deleteIssueAction.issueaction.id)
                //console.log("Instrument index", index)
                //console.log("IssueAssignment index", issueIndex)
                //console.log("Action index", actionIndex)
                cache.instruments[index].issues[issueIndex].actions.splice(actionIndex, 1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                //console.log("Cache: ",cache)
              }
            })
          })
        }
    }

    



    render(){
    return(
        <Paper>
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
            <TextTypeProvider for={['description']} />
            <TimeTypeProvider
                for={['time']}
              />
            <UserTypeProvider for={['user']} />
              <EditingState
              onCommitChanges={this.commitChanges}
              defaultAddedRows={this.props.prefill}
              />
              <Table columnExtensions={tableColumnExtensions}/>
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              /> 
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_ISSUE_ACTION, {name: 'create'}),
    graphql(UPDATE_ISSUE_ACTION, {name: 'update'}),
    graphql(DELETE_ISSUE_ACTION, {name: 'delete'})
    )(ActionTable) 
 
 
