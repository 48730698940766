import { gql } from '@apollo/client'

/*export const GET_MQTT = gql `
query GetMqtt($panID: ID!){
	mqtt(id: panID) @client
}
`*/

export const MQTT = gql`
query {
	mqtt @client
}
`

export const MQTT_FOR_PAN = gql`
query MqttForPan($panID: ID!){
	mqttForPan(panID: $panID) @client
}
`



export const ADD_MQTT_MESSAGE = gql`
  mutation AddMqttMessage($topic: String!, $message: String!) {
    addMqttMessage(topic: $topic, message: $message) @client
  }
`;