import React from 'react';
//import { connect } from 'react-redux'
//import * as ilbSelectors from '../../../store/ilb/reducer'
import { select} from 'd3-selection'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

const toTimeDistance = time => {
    return formatDistanceStrict(new Date(time*1000), new Date())
}

const transformJSON = old => {
    if(!old){return old}

    var json = {s1: {'L2_LASTSEEN':{}}, s2: {'L2_LASTSEEN':{}}}

    if(old.s1.L0_LASTSEEN){
        json.s1['L0_LASTSEEN'] = {file: old.s1.L0_LASTSEEN['filename'], age: toTimeDistance(old.s1.L0_LASTSEEN['time'])}
    }
    for (var rcode in old.s1.L2_LASTSEEN) {
        json.s1['L2_LASTSEEN'][rcode] = {file: old.s1.L2_LASTSEEN[rcode]['filename'], age: toTimeDistance(old.s1.L2_LASTSEEN[rcode]['time'])}
    }

    if(old.s2.L0_LASTSEEN){
        json.s2['L0_LASTSEEN'] = {file: old.s2.L0_LASTSEEN['filename'], age: toTimeDistance(old.s2.L0_LASTSEEN['time'])}
    }
    for (var rcode in old.s2.L2_LASTSEEN) {
        json.s2['L2_LASTSEEN'][rcode] = {file: old.s2.L2_LASTSEEN[rcode]['filename'], age: toTimeDistance(old.s2.L2_LASTSEEN[rcode]['time'])}
    }
    
    return json
}

class MqttDetail extends React.PureComponent {


    render(){
        return (<div><pre>{JSON.stringify(transformJSON(this.props.mqtt), null, 2) }</pre></div>);
    }
}







export default MqttDetail

