import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UPDATE_HARDWARE_MUTATION } from '../../../apollo/queries/hardware'
import { GET_INSTRUMENTS } from '../../../apollo/queries/instruments'
import { TIME_MASK } from './Editors'
import MaskedInput from 'react-text-mask'

const HARDWARE_SCHEMA = gql `
{
	getHardwareSchema

}
`

const buildValue = (key, data, schemaDict) => {
	if(data && data[key]){
		if(Array.isArray(data[key])){
			return Object.assign({}, ...data[key])
		}else{
			return data[key]
		}
	}
	else{
		if(schemaDict[key].length < 1){
			return new Object()
		}else{
			return Object.assign({}, ...schemaDict[key].map((kv, i) => ({ [kv[0]]: ""}) )) //kv[0] is the fieldName
		}
	}
}


const HardwareForm = (props) => {
	const schemaQuery = useQuery(HARDWARE_SCHEMA);
	//After digging a bit I noticed that sometimes props.data arrives as a dictionary, and other times it arrives as a string
	//Here it is had into account to parse the contents of props.data into data (dict)
	const [data, setData] = React.useState(props.data)
	//const [data, setData] = React.useState(
    //  typeof props.data === 'string' ? JSON.parse(props.data) : props.data || {}
    //  );

	const [predefined, setPredefined] = React.useState({})
	const [updateHardware] = useMutation(
		UPDATE_HARDWARE_MUTATION,
		{
			update: (store, {data: { updateHardwareStatusMutation }}) => {
                      if(!updateHardwareStatusMutation.ok){
                        console.log("Could not update hardware status form, response: ",updateHardwareStatusMutation);
                        return};
                      console.log("Updated hardware status form")
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                      const index = cache.instruments.findIndex(e => e.id === props.instrumentID)
                      //console.log("index:",index)
                      const hardwareIndex = cache.instruments[index].hardwarestatus.findIndex(e => e.id === props.statusID)
                      //console.log("hardwareIndex:",hardwareIndex)

                      cache.instruments[index].hardwarestatus[hardwareIndex] = updateHardwareStatusMutation.hardwarestatus
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                    }
		})

	if (schemaQuery.loading) return <p>Loading ...</p>;

	const schema = schemaQuery.data.getHardwareSchema.schema
	//console.log("schema:",schema)
	const schemaDict = Object.fromEntries(schema) // key,value list to object, for hashmap access and convenience
	//console.log("schema dict:",schemaDict)
	const excludedComponents = schemaQuery.data.getHardwareSchema.excluded
	//console.log("excluded components:",excludedComponents)
	const excludedComponentsDict = Object.fromEntries(excludedComponents) // same
	//console.log("excluded components dict: ",excludedComponentsDict)
	const instrumentDict = Object.fromEntries(excludedComponentsDict["instrument"])
	//console.log("instrumentDict:",instrumentDict)

	//console.log("rerender")



	const handleChange = (key, fieldName) => (event) => {
	    //console.log("handleChange key",key)
	    //console.log("handleChange fieldName", fieldName)
		const currentAsDict = Object.fromEntries(schemaDict[key])
		//console.log("currentAsDict: ",currentAsDict)
		const toChange = currentAsDict[fieldName] && currentAsDict[fieldName][event.target.value] //if the selection has 'choices' defined
		//console.log("toChange: ",toChange)

		if(!toChange){ // field with no predefined choices
			if(predefined.hasOwnProperty(key + ":" + fieldName)){ // set parent to 'custom' if child field changes
				setData({...data, [key]: {...data[key], [fieldName]: event.target.value, [predefined[key + ":" + fieldName]]: "custom" }})
			}else{  // no side effect, just update
				setData({...data, [key]: {...data[key], [fieldName]: event.target.value }})
			}
		}else{
			let addToPredefined = {}
			Object.keys(toChange).forEach((d,i) => {
				addToPredefined[key + ":" + d] = fieldName
			})
			setPredefined({...predefined, ...addToPredefined})
			setData({...data, [key]: {...data[key], ...toChange, [fieldName]: event.target.value }})
		}
		//console.log("data out: ",data)

		
	}

	const handleSubmit = () => {
	    console.log("handling submit")
		const emptyKeys = Object.keys(data).filter(key => Array.isArray(data[key]) && data[key].length === 0);
		const toSave = Object.fromEntries(emptyKeys.map(key => ([key, {}])));
		let variables_post = {
		  id: props.statusID,
		  //instrument: props.instrumentID,
		  data: JSON.stringify({...data, ...toSave}),
		  //user: props.user.id,
		}
		console.log("variables to post: ",variables_post)
		updateHardware({variables: {input: variables_post }})
	}

/*	if(Object.keys(data).length === 0){ // init
		setData(Object.fromEntries(Object.keys(schema).map((key,i) => (
				[key, Object.fromEntries(Object.keys(schema[key]).map((fieldName, i) => ([fieldName, ""]) ))]
			))))
		return null;
	}
*/

	if(!data || Object.keys(data).length < schema.length){ // init, but for fields that have been added recently (solves backend bug)
		console.log("init for fields that have been added recently");
		setData(Object.fromEntries(schema.map((kv,i) => {
			const key = kv[0]
			return [key, buildValue(key, data, schemaDict)] // just takes either old existing, or inits a new object based on schema
			})))
		return null;
	}



	const isExcluded = (component) => {
		const selected_model = data["instrument"]?.model
		//console.log("selected_model",selected_model);
		if(!selected_model){
		  console.log("data[instrument] not found")
		  return false
		}

		if(selected_model === undefined || selected_model === ""){
			return false
		}
		if(instrumentDict["model"][selected_model]){
			return instrumentDict["model"][selected_model].includes(component)
		}else{
			return false
		}
	}

	//render
	return (
		<Paper>
			<Button variant="contained" color="primary" onClick={handleSubmit} >save changes</Button>
			{schema.map((kv, i) => {
				const [component, fields] = kv
				return(
				isExcluded(component) ? null : 
				<div key={component} style={{paddingLeft: "10px"}} >
				<Typography variant="h6">
				{component}
				</Typography>
				{ schemaDict[component].map((kv, i) => {
					const [fieldName, choices] = kv
					if(!choices){
						return (<div key={component + ":" + fieldName}>
							<TextField key={component + ":" + fieldName} style={{minWidth: "500px", left: "50px"}}
							label={fieldName} defaultValue={data[component][fieldName] || ""} onBlur={handleChange(component, fieldName)}/>
							</div>)
					}else if(choices === "multiline"){
						return (<div key={component + ":" + fieldName}>
							<TextField key={component + ":" + fieldName} style={{minWidth: "500px", left: "50px"}} multiline
							label={fieldName} defaultValue={data[component][fieldName] || ""} onBlur={handleChange(component, fieldName)}/>
							</div>)
					}
					else if(choices === "date"){
						return (<div key={component + ":" + fieldName}>
						      <MaskedInput
						        mask={TIME_MASK}
						        showMask
						        keepCharPositions
						        defaultValue={ data[component][fieldName] }
						        //onChange={event => { onValueChange(moment.utc(event.target.value, TIME_FORMAT)) } }
						        onBlur={handleChange(component, fieldName) } 
						      />
						      </div>)
					}else{
						return (<div key={component + ":" + fieldName}>
							<TextField key={component + ":" + fieldName} style={{minWidth: "500px", left: "50px"}}
							select label={fieldName} value={data[component][fieldName] || ""} onChange={handleChange(component, fieldName)} >
							          {Object.keys(choices).map(option => {
							          	//{Object.keys(schema[component][fieldName]).map(option => (
							         	return (	
								            <MenuItem key={fieldName + option } value={option}>
								              {option}
								            </MenuItem>
							            )

							          })}
							          <MenuItem key="custom" value="custom">
							          custom
							          </MenuItem>
							     		<MenuItem key="nothing" value="">
							          nothing
							          </MenuItem>
							</TextField>
							</div>)
					}


					})}
				</div>)
			}
				)}
		</Paper>
		)
}

export default HardwareForm;