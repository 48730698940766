import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
    TableEditRow,
  TableEditColumn,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import {
  EditingState,
  //SortingState,
  //IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import { CREATE_ISSUE_ASSIGNMENT, UPDATE_ISSUE_ASSIGNMENT, DELETE_ISSUE_ASSIGNMENT } from '../../apollo/queries/issue'
import { CREATE_STATUS_ASSIGNMENT } from '../../apollo/queries/status'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { IssueCategoryTypeProvider, IssueDescriptionTypeProvider, TimeTypeProvider, UserTypeProvider, CommandIcons} from './utils/Editors'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import ActionTable from './ActionTable'
import { DJANGO_URL } from '../../constants'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';


function StatusDialog(props) {

    const { onClose, ...other } = props

    function handleYes(){
        onClose(true)
    }

    function handleNo(){
        onClose(false)
    }

    return(
      <Dialog onClose={handleNo} {...other} >
        <DialogTitle>Auto-Create a status assignment with 'hold due to issue'?</DialogTitle>
        <Button onClick={handleYes}>Yes</Button>
        <Button onClick={handleNo}>No</Button>
      </Dialog>
    )

}





const columns = [
{
    name: 'category',
    //title: <a href={DJANGO_URL + "admin/stations/issuecategory/"} target="_blank" >Category</a>,
    title: <a href={DJANGO_URL + "admin/stations/issuecategory/"} target="_blank" rel="noopener noreferrer">Category</a>,
    getCellValue: row => (row.issue && row.issue.category)
},
{
  name: 'issue',
  //title: <a href={DJANGO_URL + "admin/stations/issue/"}target="_blank" >Description</a>,
  title: <a href={DJANGO_URL + "admin/stations/issue/"} target="_blank" rel="noopener noreferrer">Description</a>,
  getCellValue: row => (row.issue)
},
{
    name: 'opened',
    title: 'Opened'
},
{
    name: 'closed',
    title: 'Closed'
},
{
  name: 'user',
  title: 'User'
}]

const tableColumnExtensions = [
    {columnName: 'category', width: 100},
    {columnName: 'issue', width: 480},
]

const flattenNesting = obj => (obj.issue ? {...obj, issue: obj.issue.id} : obj)


class IssueTable extends React.PureComponent {

  state = { expandedRow: undefined, open: false, data: undefined}


    handleTrigger = yes => {
      if(yes){
        const description = "automatically created due to: " + this.state.data.issue.category.name + " " + this.state.data.issue.description
        this.props.createStatus({
          variables: {input: {instrument: this.props.instrumentID, status: 2, user: this.props.user.id, description: description, time: this.state.data.opened}},
          update: (store, {data: { createStatusAssignment }}) => {
                if(!createStatusAssignment.ok){return}
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].stati.unshift(createStatusAssignment.statusassignment)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
        })
      }
      this.setState({open: false, data: undefined})
    }

    commitChanges = ({added, changed, deleted}) => {
        let { data } = this.props
        //data contains all issue assignments of the instrument.
        // each issue assignment have a unique id

        if(added){
          added.forEach(x => {
            let variables_post = {
              instrument: this.props.instrumentID,
              issue: x.issue.id,
              opened: x.opened,
              user: this.props.user.id
            };
            console.log("Creating issue assignment: ",variables_post)
            this.props.create({
              variables: {input: variables_post},
              update: (store, {data: { createIssueAssignment }}) => {
                if(!createIssueAssignment.ok){
                  console.log("Could not create issue assignment, response:",createIssueAssignment);
                  return}
                console.log("Created issue assignment: ",createIssueAssignment)
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                cache.instruments[index].issues.unshift(createIssueAssignment.issueassignment)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
                this.setState({open: true, data: x, expandedRow: createIssueAssignment.issueassignment.id, prefillAction: createIssueAssignment.issueassignment.opened})

              }
            })
          })
        }

        //Changed issue assignments
        if(changed){
          //for every issue assignment
          data.forEach(x => {
            //If this specific issue assignment was changed (=edited+saved)
            if(changed[x.id]){
                 console.log("Current issue assignment object: ",x);
                 console.log("Changed: ",changed[x.id]);
                 let variables_post = {
                     id:x.id, //unique id of the issue assignment
                     instrument: this.props.instrumentID,
                     user:this.props.user.id,
                     issue: x.issue.id //id of the issue (issue description)
                     };

                 variables_post = {
                   ...variables_post,
                   ...flattenNesting(changed[x.id]) //this will unpack the opening and or closing date
                   };

                 console.log("Updating issue assignment: ",variables_post);
                 this.props.update({
                    variables: {input:variables_post},
                    update: (store, {data: { updateIssueAssignment }}) => {
                      //console.log(`Mutation response:`, updateIssueAssignment);}
                      if(!updateIssueAssignment.ok){
                        console.log('Could not update issue assignment, response:', updateIssueAssignment);
                        return};
                      console.log("Issue assignment updated ", updateIssueAssignment)
                      const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})));
                      const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID);
                      const issueIndex = cache.instruments[index].issues.findIndex(e => e.id === x.id);
                      cache.instruments[index].issues[issueIndex] = updateIssueAssignment.issueassignment;
                      store.writeQuery({query: GET_INSTRUMENTS, data: cache});
                      if(updateIssueAssignment.issueassignment.closed){
                        this.setState({prefillAction: updateIssueAssignment.issueassignment.closed, expandedRow: updateIssueAssignment.issueassignment.id});
                      }
                    }
                });
            };
          });
        }
        
        if(deleted){
          deleted.forEach(x => {
            console.log("Deleting issue assignment object: ",x)
            this.props.delete({
              variables: {input: x},
              update: (store, {data: { deleteIssueAssignment }}) => {
                if(!deleteIssueAssignment.ok){
                  console.log("Could not delete issue assignment, response: ",deleteIssueAssignment)
                  return};
                console.log("Issue assignment deleted")
                const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
                const index = cache.instruments.findIndex(e => e.id === this.props.instrumentID)
                const issueIndex = cache.instruments[index].issues.findIndex(e => e.id === x)
                cache.instruments[index].issues.splice(issueIndex,1)
                store.writeQuery({query: GET_INSTRUMENTS, data: cache})
              }
            })
          })
        }
    }

    RowDetail = ({row}) => (<ActionTable data={row.actions} issueAssignmentID={row.id} user={this.props.user}
      prefill={this.state.prefillAction ? [{issue: row.id, description: "enter details", time: this.state.prefillAction}] : [] }
      instrumentID={this.props.instrumentID} />)

    handleRowClick = id => () => { this.state.expandedRow !== id ? this.setState({expandedRow: id, prefillAction: undefined}) : this.setState({expandedRow: undefined, prefillAction: undefined}) }

    Cell = ({column, row, ...restProps}) => (<Table.Cell {...restProps} onClick={this.handleRowClick(row.id)} style={{ cursor: 'pointer'}} />)
    ToggleComponent = (props) => (<Table.StubCell {...props} />)

    render(){
    return(
        <Paper>
            <StatusDialog open={this.state.open} onClose={this.handleTrigger} />
            <Grid
              rows={this.props.data}
              columns={columns}
              getRowId={row => row.id}
            >
             <IssueCategoryTypeProvider
                for={['category']}
              />
            <IssueDescriptionTypeProvider
              for={['issue']}
              />
            <TimeTypeProvider
                for={['opened', 'closed']}
              />
            <UserTypeProvider for={['user']} />
              <EditingState
              onCommitChanges={this.commitChanges}
              columnExtensions= {
                [{columnName: 'category',
                  createRowChange: (row, value) => (value == null ? {issue: value} : {issue: { category: value}})
                },
                {
                  columnName: 'issue',
                    createRowChange: (row, value) => { return {issue: value}}
                }]
              }
              />
            <RowDetailState
                expandedRowIds={this.state.expandedRow ? [this.state.expandedRow] : []}
              />
              <Table cellComponent={this.Cell} columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
                <TableEditRow />
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={CommandIcons}
              />
              <TableRowDetail
                contentComponent={this.RowDetail}
                toggleCellComponent={this.ToggleComponent}
                toggleColumnWidth={0}
              /> 
            </Grid>
          </Paper>
      )
    }
}



export default compose(
    graphql(CREATE_ISSUE_ASSIGNMENT, {name: 'create'}),
    graphql(UPDATE_ISSUE_ASSIGNMENT, {name: 'update'}),
    graphql(DELETE_ISSUE_ASSIGNMENT, {name: 'delete'}),
    graphql(CREATE_STATUS_ASSIGNMENT, {name: 'createStatus'})
    )(IssueTable) 
 
