import { gql } from '@apollo/client'

export const ALL_PARTIAL_HEADERS = gql `
query AllPartialHeaders($start: DateTime, $end: DateTime){
	instruments {
		id
		panId
		partialHeaders(time_Gte: $start, time_Lte: $end) {
			data
			time
			spec
		}
	}
}
`

export const PARTIAL_HEADERS = gql `
query PartialHeaders($input: ID!, $start: DateTime, $end: DateTime) {
	instrument(id: $input) {
		currentLocation {
			timezone
			name
		}
		partialHeaders(time_Gte: $start, time_Lte: $end) {
			data
			time
			spec
		}
	}
}
`


