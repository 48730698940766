import { gql } from '@apollo/client'

export const GET_STATI = gql`
query {
    allStati {
        results {
            id
            name
        }
        totalCount
    }
}
`;



export const UPDATE_STATUS_ASSIGNMENT = gql`
  mutation UpdateStatusAssignment($input: StatusAssignmentUpdateGenericType!) {
    updateStatusAssignment(newStatusassignment: $input){
        ok
        statusassignment {
              id
              time
              description
              status {
                id
                name
              }
              user {
                username
              }
        }
    }
  }
`;

export const CREATE_STATUS_ASSIGNMENT = gql`
  mutation CreateStatusAssignment($input: StatusAssignmentCreateGenericType!) {
    createStatusAssignment(newStatusassignment: $input){
        ok
        statusassignment {
              id
              time
              description
              status {
                id
                name
              }
              user {
                username
              }
        }
    }
  }
`;

export const DELETE_STATUS_ASSIGNMENT = gql`
  mutation DeleteStatusAssignment($input: ID!) {
    deleteStatusAssignment(id: $input){
        ok
        statusassignment {
              id
              time
              description
              status {
                id
                name
              }
              user {
                username
              }
        }
    }
  }
`;
