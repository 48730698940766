import { select} from 'd3-selection'

import React from 'react'

const MARGIN = {
    top: 15,
    bottom: 15,
    left: 5,
    right: 70,

}

const BLOCK_SIZE = 20
const PADDING = 1
const FONT_SIZE = 12

class CalibrationViz extends React.PureComponent {

    componentDidUpdate(){
        select(this.node).selectAll("g").remove()
        this.createGraph()
    }

    componentDidMount(){
        this.createGraph()
    }

    createGraph = () => {
        const { data, size } = this.props

        const rows = Object.keys(data)
        if(rows.length < 1){return}
        const measured = Object.entries(data["measured"]).sort((a,b)=>a[0].localeCompare(b[0]))
        const verified = Object.entries(data["verified"]).sort((a,b)=>a[0].localeCompare(b[0]))

    
        const hasFunction = this.props.handleClick ? true : false
        const numColumns = measured.length

        var svg = select(this.node)
        svg.attr("width", (BLOCK_SIZE*numColumns)*2 +MARGIN.left+MARGIN.right)
        svg.attr("height", BLOCK_SIZE*2 + MARGIN.top + MARGIN.bottom)

        var groupMeasured = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

        var groupVerified = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + (MARGIN.top+BLOCK_SIZE) + ")")

        groupMeasured.selectAll(".measured").data(measured).enter()
                    .append("rect").attr("y", PADDING).attr("width", BLOCK_SIZE-2*PADDING).attr("height", BLOCK_SIZE-2*PADDING)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("fill", d=> d[1] ? 'green' : 'grey')
                    .on("click", (e, d) => hasFunction ? this.props.handleClick("measured")(d[0]) : console.log("clicked"))

        groupMeasured.selectAll(".measuredLabel").data(measured).enter()
                    .append("text").attr("y", -2)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("font-size", FONT_SIZE)
                    .text(d=>d[0])

        groupVerified.selectAll(".verified").data(verified).enter()
                    .append("rect").attr("y", PADDING).attr("width", BLOCK_SIZE-2*PADDING).attr("height", BLOCK_SIZE-2*PADDING)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("fill", d=> d[1] ? 'green' : 'grey')
                    .on("click", (e, d) => hasFunction ? this.props.handleClick("verified")(d[0]) : void(0))


        groupMeasured.append("text").attr("x", BLOCK_SIZE*numColumns + MARGIN.left).attr("y", BLOCK_SIZE/2 + 2).attr("font-size", FONT_SIZE)
                    .text("measured")

        groupVerified.append("text").attr("x", BLOCK_SIZE*numColumns + MARGIN.left).attr("y", BLOCK_SIZE/2 + 2).attr("font-size", FONT_SIZE)
                    .text("verified")
        groupVerified.append("text").attr("x", BLOCK_SIZE*numColumns/2 + MARGIN.left).attr("y", BLOCK_SIZE + 10).attr("font-size", FONT_SIZE)
                    .text("s1")


        if(!data.hasOwnProperty('measured_s2')){
            return
        }

        const measured_s2 = Object.entries(data["measured_s2"]).sort((a,b)=>a[0].localeCompare(b[0]))
        const verified_s2 = Object.entries(data["verified_s2"]).sort((a,b)=>a[0].localeCompare(b[0]))

        var groupMeasuredS2 = svg.append("g")
                    .attr("transform", "translate(" + ( MARGIN.right + (BLOCK_SIZE*numColumns)) + "," + MARGIN.top + ")")

        var groupVerifiedS2 = svg.append("g")
                    .attr("transform", "translate(" + ( MARGIN.right + (BLOCK_SIZE*numColumns)) + "," + (MARGIN.top+BLOCK_SIZE) + ")")

        groupMeasuredS2.selectAll(".measured").data(measured_s2).enter()
                    .append("rect").attr("y", PADDING).attr("width", BLOCK_SIZE-2*PADDING).attr("height", BLOCK_SIZE-2*PADDING)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("fill", d=> d[1] ? 'green' : 'grey')
                    .on("click", (e, d) => hasFunction ? this.props.handleClick("measured_s2")(d[0]) : console.log("clicked"))

        groupMeasuredS2.selectAll(".measuredLabel").data(measured_s2).enter()
                    .append("text").attr("y", -2)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("font-size", FONT_SIZE)
                    .text(d=>d[0])

        groupVerifiedS2.selectAll(".verified").data(verified_s2).enter()
                    .append("rect").attr("y", PADDING).attr("width", BLOCK_SIZE-2*PADDING).attr("height", BLOCK_SIZE-2*PADDING)
                    .attr("x", (d,i) => PADDING + BLOCK_SIZE*i)
                    .attr("fill", d=> d[1] ? 'green' : 'grey')
                    .on("click", (e, d) => hasFunction ? this.props.handleClick("verified_s2")(d[0]) : void(0))


        groupMeasuredS2.append("text").attr("x", BLOCK_SIZE*numColumns + MARGIN.left).attr("y", BLOCK_SIZE/2 + 2).attr("font-size", FONT_SIZE)
                    .text("measured")

        groupVerifiedS2.append("text").attr("x", BLOCK_SIZE*numColumns + MARGIN.left).attr("y", BLOCK_SIZE/2 + 2).attr("font-size", FONT_SIZE)
                    .text("verified")

        groupVerifiedS2.append("text").attr("x", BLOCK_SIZE*numColumns/2 + MARGIN.left).attr("y", BLOCK_SIZE + 10).attr("font-size", FONT_SIZE)
                    .text("s2")


        
    }

    render(){
        return(<svg ref={node => this.node = node}  />)
    }
}

export default CalibrationViz
