import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Message from '@material-ui/icons/Message'
import { graphql } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, GET_INSTRUMENTS } from '../../../apollo/queries/instruments'
import { TIME_FORMAT, TIME_MASK } from './Editors'
import * as moment from 'moment'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import isDate from 'date-fns/isDate'
import MaskedInput from 'react-text-mask'
import Edit from '@material-ui/icons/Edit'


class CommentEditDialog extends React.PureComponent {
  state = {
    open: false,
    value: this.props.data.body,
    date: moment.utc(this.props.data.time, TIME_FORMAT)
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleUpdate = () => {

    let variables_post = {
            id: Number(this.props.data.elementID),
            text: this.state.value,
            user: this.props.user.id,
            time: this.state.date,
            instrument: this.props.instrumentID
        };
    console.log("Updating commentary: ",variables_post)
    this.props.update({
        variables: {input: variables_post},
        update: (store, {data: { updateComment }}) => {
          if(!updateComment.ok){
           console.log("Could not update commentary, response: ",updateComment)
          return};
          console.log("Commentary updated")
          const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
          const index = cache.instruments.findIndex(e => e.panId === this.props.panID)
          const commentIndex = cache.instruments[index].comments.findIndex(e => e.id === this.props.data.elementID)
          cache.instruments[index].comments[commentIndex] = updateComment.comment
          store.writeQuery({query: GET_INSTRUMENTS, data: cache})
        }
    })
    this.setState({open: false, value: undefined, date: undefined})
  }

  handleDelete = () => {
    this.props.delete({
        variables: {input: Number(this.props.data.elementID)},
        update: (store, {data: { deleteComment }}) => {
          if (!deleteComment.ok){
              console.log("Could not delete commentary, response: ",deleteComment)
              return};
          console.log("Commentary deleted")
          const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
          const index = cache.instruments.findIndex(e => e.panId === this.props.panID)
          const commentIndex = cache.instruments[index].comments.findIndex(e => e.id === this.props.data.elementID)
          cache.instruments[index].comments.splice(commentIndex,1)
          store.writeQuery({query: GET_INSTRUMENTS, data: cache})
        }
    })
    this.setState({open: false, value: undefined, date: undefined})
  }

  render() {
    return (
      <div>
        <Button color="primary" onClick={this.handleClickOpen}>
          <Edit />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Comment on {this.props.panID}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              multiline
              id="comment"
              label="Comment"
              fullWidth
              defaultValue={this.state.value}
              onChange={e => this.setState({ value: e.target.value })}
            />
            {
              this.state.date ?  
                <MaskedInput
                  mask={TIME_MASK}
                  showMask
                  keepCharPositions
                  defaultValue={moment.utc(this.state.date).format(TIME_FORMAT) }
                  onChange={event => { this.setState({ date: moment.utc(event.target.value, TIME_FORMAT) }) } }
                />
                : <Button onClick={ () => this.setState({ date: moment.utc() }) }>Now</Button>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleUpdate} color="primary">
              Submit
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}





class CommentCreateDialog extends React.PureComponent {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCreate = () => {
    this.props.create({
        variables: {input: {instrument: Number(this.props.instrumentId), text: this.state.value, user: this.props.user.id, time: this.state.date}},
        update: (store, {data: { createComment }}) => {
          if(!createComment.ok){ return }
          const cache = JSON.parse(JSON.stringify(store.readQuery({query: GET_INSTRUMENTS})))
          const index = cache.instruments.findIndex(e => e.panId === this.props.panID)
          cache.instruments[index].comments.unshift(createComment.comment)
          store.writeQuery({query: GET_INSTRUMENTS, data: cache})
        }
    })
    this.setState({open: false, value: undefined, date: undefined})
  }

  render() {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          create comment
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Comment on {this.props.panID}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              multiline
              id="comment"
              label="Comment"
              fullWidth
              onChange={e => this.setState({ value: e.target.value })}
            />
            {
              this.state.date ?  
                <MaskedInput
                  mask={TIME_MASK}
                  showMask
                  keepCharPositions
                  defaultValue={moment.utc(this.state.date).format(TIME_FORMAT) }
                  onChange={event => { this.setState({ date: moment.utc(event.target.value, TIME_FORMAT) }) } }
                />
                : <Button onClick={ () => this.setState({ date: moment.utc() }) }>Now</Button>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCreate} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export const CommentCreateWidget = compose(
    graphql(CREATE_COMMENT, {name: 'create'})
    )(CommentCreateDialog)


export const CommentEditWidget = compose(
  graphql(UPDATE_COMMENT, {name: 'update'}),
  graphql(DELETE_COMMENT, {name: 'delete'})
  )(CommentEditDialog)

