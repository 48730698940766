import { gql } from '@apollo/client'


export const LOCATION_DATA =  gql`
        fragment LocationData on LocationGenericType {
            id
            name
            lat
            lon
            alt
            timezone
        }
    `;

export const GET_LOCATIONS = gql`
query {
    allLocations {
        results {
            ...LocationData
        }
        totalCount
    }
}
${LOCATION_DATA}
`;


export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: LocationUpdateGenericType!) {
    updateLocation(newLocation: $input){
        ok
        location {
            ...LocationData
        }
    }
  }
  ${LOCATION_DATA}
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($input: ID!) {
    deleteLocation(id: $input){
        ok
        location {
            ...LocationData
        }
    }
  }
  ${LOCATION_DATA}
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: LocationCreateGenericType!) {
    createLocation(newLocation: $input){
        ok
        location {
            ...LocationData
        }
    }
  }
  ${LOCATION_DATA}
`; 
