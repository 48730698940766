import { gql } from '@apollo/client'

export const GET_TOKEN = gql `
mutation TokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
  }
}
`

export const VERIFY_TOKEN = gql `
mutation VerifyToken($token: String!) {
  verifyToken(token: $token) {
    payload
  }
}
`

export const GET_USER = gql `
query {
	user @client
}
`

export const HAS_TOKEN = gql `
query {
	hasToken @client
}
`

