 
import * as React from 'react';
import SummaryTimeline from '../../components/timelines/Summary'
import Log from '../../components/Log'
import { CommentCreateWidget } from './utils/CommentDialog'
import InstrumentStaticNotes from './utils/InstrumentStaticNotes'
import PartialHeaders from './utils/PartialHeaders'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import DiagnosisPlot from '../../components/DiagnosisPlot'



class InstrumentDetail extends React.PureComponent {

    state = {interval: undefined}

    handleZoom = interval => {this.interval = interval}

    handleSync = () => {this.setState({interval: this.interval})}
    handleUnSync = () => {this.setState({interval: undefined})}

  render() {
        const { interval } = this.state
        return (
            <Paper style={{"flex": "1", "flexDirection": "column", "display": "flex"}}>
              <SummaryTimeline style={{margin: 'auto', marginBottom: '50'}} size={[1100,50]} 
              data={this.props.data} daysDelta={365} handleZoom={this.handleZoom} />
              <div style={{flex: '1', display: "flex"}}>
              <CommentCreateWidget user={this.props.user} panID={this.props.data.panId} instrumentId={this.props.data.id} />
              <PartialHeaders panID={this.props.data.panId} instrumentID={this.props.data.id} />
              <Button variant="outlined" onClick={this.handleSync} >filter</Button>
              <Button variant="outlined" onClick={this.handleUnSync} >reset</Button>
              { this.props.data.deployment ?
              <DiagnosisPlot panID={this.props.data.panId} location={this.props.data.deployment[0].location.name} /> :
              null
              }
              </div>
              <InstrumentStaticNotes data={this.props.data} user={this.props.user}/>
              <Log data={this.props.data} user={this.props.user} interval={interval} />
            </Paper>
          );
  }
}

export default InstrumentDetail; 
