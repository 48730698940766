import * as React from 'react';
import { scaleLinear, scaleTime, scaleUtc } from 'd3-scale'
import { extent } from 'd3-array'
import { select} from 'd3-selection'
import { line, curveBundle} from 'd3-shape'
import { axisTop, axisRight, axisLeft, axisBottom} from 'd3-axis'
import addDays from 'date-fns/addDays'
import { ISSUE_COLORS } from '../../constants'


const MARGIN = {
    top: 5,
    bottom: 5,
    left: 5,
    right: 60
}

//looking up "hw.cn" from "hw.cn.bla.bla.bla" in the ISSUE_COLORS dict
const getColor = (category) => {
    const split = category.split('.')
    const lookup = split.length < 2 ? category : split[0]+ "." + split[1]
    return ISSUE_COLORS[lookup]
}




const getIntervals = (data) => {

    var rows = []

    data.forEach((d,i) => {
        if(!d.closed){
            rows.push(d.issue.category.name)
        }
    })

    return Array.from( new Set(rows))
}

const ROW_HEIGHT = 12

class IssueTimeline extends React.PureComponent {

    constructor(props){
        super(props)
        this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
        this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom
    }

    componentDidMount(){
        this.createGraph()
    }
    
    componentDidUpdate(){
        select(this.node).selectAll("g").remove()
        this.createGraph()
    }

    createGraph = () => {

        if(!this.props.data || this.props.data.length < 1) { return;}

        var svg = select(this.node)
        const data = getIntervals(this.props.data)

        if(data.length <1){return}

        this.HEIGHT = ROW_HEIGHT * data.length
        svg.attr("height", this.HEIGHT+ MARGIN.top + MARGIN.bottom)


        var group = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

    
        var categories = group.selectAll("g").data(data).enter()
            .append("g").attr("transform", (d,i) => ("translate(0,"+ i*ROW_HEIGHT + ")"))

        categories.append("rect").attr("fill", d=> getColor(d))
            .attr("width", this.WIDTH)
            .attr("y", 2).attr("height", ROW_HEIGHT-4)


/*        categories.each(function(p,i){
            select(this).selectAll("rect").data(p=>[p]).enter()
            .append("rect").attr("fill", getColor(p.value))
            .attr("x", d => xScale(d[0]))
            .attr("width", d=> xScale(d[1]) - xScale(d[0]))
            .attr("y", 2).attr("height", ROW_HEIGHT-4)
        })*/
        
        

        categories.append("text").attr("transform", "translate(" + (this.WIDTH + 5 )+ ",0)")
                .attr("text-anchor", "left")
                .attr("font-size", "10px")
                .attr("y", 9)
                .text(d=>d)


    }

    render(){

        

        return(
          <svg ref={node => this.node = node} 
          width={this.props.size[0]} height={this.props.size[1]} />
          )
    }
}

export default IssueTimeline;
 
