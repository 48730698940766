import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { EditingState, RowDetailState, SearchState, IntegratedFiltering, FilteringState,
        SortingState, IntegratedSorting, DataTypeProvider} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';

import { graphql, Query } from 'react-apollo'
import * as compose from 'lodash.flowright'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_INSTRUMENTS } from '../../apollo/queries/instruments'
import { MQTT_FOR_PAN, MQTT } from '../../apollo/queries/mqtt'
import apolloClient from '../../apollo/client'
import DeploymentTable from './DeploymentTable'
import StatusTable from './StatusTable'
import IssueTable from './IssueTable'
import CalibrationTable from './CalibrationTable'
import { withStyles } from '@material-ui/core/styles';
import StatusTimeline from '../../components/timelines/Status'
import IssueTimeline from '../../components/timelines/Issue'
import CalibrationTimeline from '../../components/timelines/Calibration'
import CommentDialog from './utils/CommentDialog'
import MqttTest from './utils/MqttTest'
import MqttDetail from './utils/MqttDetail'
import HardwareTable from './HardwareTable'
import InstrumentSummary from './InstrumentSummary'
import Typography from '@material-ui/core/Typography'
import { STATUS_SORTING } from '../../constants'
import FilterPGN from './utils/FilterPGN'
//import store from '../../store/index'
//import * as ilbSelectors from '../../store/ilb/reducer'


const getRowId = row => row.id

const styles = theme => ({
  selected: {
    //backgroundColor: theme.palette.background.paper,
    backgroundColor: 'whitesmoke',
  },
});

const SQUARE_SIZE = 20

const StatusFormatter = ({row}) => (<StatusTimeline data={row.stati} size={[SQUARE_SIZE, SQUARE_SIZE]} />)

const StatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={StatusFormatter}
    {...props}
  />
)

const CalibrationFormatter = ({row}) => (<CalibrationTimeline data={row.calibrations} size={[SQUARE_SIZE, SQUARE_SIZE]} />)

const CalibrationTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={CalibrationFormatter}
    {...props}
  />
)



const MqttFormatter = ({value, row}) => (<MqttTest mqtt={value} panId={row.panId} size={[150, 40]} />)

const MqttTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={MqttFormatter}
    {...props}
  />
)




const sortingExtensions = [{
  columnName: "currentStatus", compare: (a,b) => (STATUS_SORTING[a] - STATUS_SORTING[b])
},
{
  columnName: "mqtt", compare: (a,b) => {
    if(!a){
      return 1
    }else if(!b){
      return -1
    }

    return b.s1.L0_LASTSEEN.time - a.s1.L0_LASTSEEN.time
  }
}]


const stylesTable = theme => ({
  tableStriped: {
    '& tbody > tr:nth-of-type(odd)': {
      backgroundColor: '#f4f8ff',
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
  />
);

const TableComponent = withStyles(stylesTable, { name: 'TableComponent' })(TableComponentBase);

//(props.row.id % 2) == 0 ? {backgroundColor: '#000000'} : {}

/*const TableRow = (props) => {
  const { tableRow, ...other} = props
  const { row, ...otherTableRow } = tableRow
  const { data } = useQuery(MQTT_FOR_PAN, { variables:{ panID: row ? row.panId : -1} })
  return(
  <Table.Row {...other} tableRow={{...otherTableRow, row: {...row, mqtt: data ? data.mqttForPan : null}}} />
  )
}*/




class EditableTable extends React.PureComponent {

    state = {expandedRow: undefined,
            expandedColumn: undefined,
            filters: []}

    handleCellClick = (column, rowId) => {
	    if ((column === "hardwarestatus" && this.props.user.hweditor) || column !== "hardwarestatus") {
		    return () => {return column !== this.state.expandedColumn || rowId !== this.state.expandedRow ?
                        this.setState({expandedRow: rowId, expandedColumn: column})
                        : this.setState({expandedRow: undefined, expandedColumn: undefined})}
	    } else {
		    return () => undefined;
	    }
    }

    Cell = (props) => {
      const { classes, ...other } = props
      const { column, row, value} = other;

      return (<Table.Cell {...other} onClick={this.handleCellClick(column.name, row.id)}
                style={{cursor: "pointer", paddingTop: "0px", paddingBottom: "0px", borderBottom: "0px"}} 
                className={this.state.expandedColumn === column.name && this.state.expandedRow === row.id ? classes.selected : ""} />
                );
    }

    StyledCell = withStyles(styles)(this.Cell)

    RowDetail = ({row}) =>{
    switch(this.state.expandedColumn){
        case "panId":
            return <InstrumentSummary data={row} user={this.props.user} />
        case "isAt":
            return <DeploymentTable data={row.deployment} instrumentID={row.id} user={this.props.user} />
        case "currentStatus":
            return <StatusTable data={row.stati} instrumentID={row.id} user={this.props.user}/>
        case "openIssues":
            return <IssueTable data={row.issues} instrumentID={row.id} user={this.props.user} />
        case "calibrations":
            return <CalibrationTable data={row.calibrations} instrumentID={row.id} user={this.props.user}/>
        case "mqtt":
            return <MqttDetail panId={row.panId} mqtt={row.mqtt} />
        case "hardwarestatus":
            return <HardwareTable data={row.hardwarestatus} instrumentID={row.id} user={this.props.user} />
        default:
            return <p>nothing to see here</p>
    }
    }

    ContainerComponent = props => (<Table.Container {...props} style={{overflow: "visible"}} />)
    ToggleComponent = props => (<Table.StubCell {...props} />)//toggleCellComponent={(props)=>(<Table.StubCell {...props} />)}

    render() {

      //merge mqtt into instruments table, in order to get the livestatus as ordinary column in the table
      const mqtt = this.props.mqtt ? this.props.mqtt.mqtt : null
      let data
      if(mqtt){
        data = []
        this.props.data.forEach((d,i)=>{
          if(d.panId in mqtt){
            data.push({...d, mqtt: mqtt[d.panId] })
          }else{
            data.push(d)
          }
        })
      }else{
        data = this.props.data
      }

      

    return (
      <Paper>
        <Grid
          rows={data}
          columns={this.props.columns}
          getRowId={getRowId}
          style={{overflow: "hidden"}}
        >
        <StatusTypeProvider for={['currentStatus']} />
        <MqttTypeProvider for={['mqtt']} />
        <CalibrationTypeProvider for={['calibrations']} />
          <SortingState
            defaultSorting={[{ columnName: 'panId', direction: 'asc' }]}
          />
          <IntegratedSorting columnExtensions={sortingExtensions}/>
          <SearchState defaultValue="" />
          <FilteringState
            filters={this.state.filters}
            //onFiltersChange={setFilters}
          />
          <IntegratedFiltering />
            <RowDetailState
                expandedRowIds={this.state.expandedRow ? [this.state.expandedRow] : []}
              />
          <Table tableComponent={TableComponent} cellComponent={this.StyledCell} containerComponent={this.ContainerComponent} 
             />
          <TableHeaderRow showSortingControls  />
          <Toolbar />
          <FilterPGN filtered={this.state.filters.length > 0}
          toggleFilter={() => this.state.filters.length > 0 ? this.setState({filters: []}) : this.setState({filters: [{columnName: 'pgn', value: true}]})} />
          <SearchPanel />
          <TableRowDetail
            contentComponent={this.RowDetail}
            toggleCellComponent={this.ToggleComponent}
            toggleColumnWidth={0}
          /> 
        </Grid>
      </Paper>
    );
  }
}

const getHardwareName = (hwStatus) => {
  var res = ""
  if(!hwStatus.data["instrument"]){
    return res
  }
  if(hwStatus.data["instrument"].model){
    res = res.concat(hwStatus.data["instrument"].model)
  }
  if(hwStatus.data["instrument.tracker"].model){
        res = res.concat(" - " + hwStatus.data["instrument.tracker"].model)
  }
  return(res)
}

const columns = [{
    name: 'panId', 
    title: 'Pan ID'
},
{
    name: 'isAt',
    title: 'Location',
    getCellValue: row => (row.deployment.length > 0 ? row.deployment[0].location.name : 'None')
},
{
    name: 'currentStatus',
    title: 'Status',
    getCellValue: row => (row.stati.length > 0 ? row.stati[0].status.name : 'None')
    //getCellValue: row => (<StatusTimeline data={row.stati} size={[80, 20]} daysDelta={14} />)
},
{
    name: 'mqtt',
    title: 'Live Status',
    //getCellValue: row => (<MqttWrapper panId={row.panId} />)

},
{
    name: 'openIssues',
    title: 'Issues',
    //getCellValue: row => (row.issues.length > 0 ? row.issues[0].issue.description : 'None')
    getCellValue: row => (<IssueTimeline data={row.issues} size={[90, 30]} />)
},
{
  name: 'calibrations',
  title: 'Calibrations',
  getCellValue: row => (row.calibrations.length > 0 ? row.calibrations[0].data : 'None')
},
{
  name: 'hardwarestatus',
  title: 'Hardware',
  getCellValue: row => (row.hardwarestatus.length > 0 ? getHardwareName(row.hardwarestatus[0]) : 'None')
},
{
  name: 'networkOperator',
  title: 'Operator',
  getCellValue: row => (row.networkOperator && row.networkOperator.username)
}]

const InstrumentView = (props) => {
  const mqtt = useQuery(MQTT)
  return(
  <Query query={GET_INSTRUMENTS} pollInterval={90000} >
    {({loading, error, data, networkStatus}) => {

        if (networkStatus !== 6 && loading) return <p>Loading...</p>;
        if (error) {
          console.error("GET_INSTRUMENTS, error from instruments: ", error.message);
          return <p>Error from Instuments</p>;
        }


        return (
                <EditableTable columns={columns} mqtt={mqtt.data}
                data={data.instruments} user={props.user}
                />
                );

    }}
    </Query>
    )

}


export default InstrumentView 
