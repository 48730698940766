import * as React from 'react';
import { select} from 'd3-selection'


import { STATUS_COLORS } from '../../constants'


const MARGIN = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}


class StatusTimeline extends React.PureComponent {

    constructor(props){
        super(props)
        this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
        this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom
    }

    componentDidMount(){
        this.createGraph()
    }

    componentDidUpdate(){
        select(this.node).selectAll("g").remove()
        this.createGraph()
    }

    createGraph = () => {

        if(!this.props.data || this.props.data.length < 1) { return;}

        var svg = select(this.node)
        const data = this.props.data[0].status.name


        var group = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")



        group.selectAll(".rects").data([data]).enter()
            .append("rect")
            .attr("fill", d => STATUS_COLORS[d])
            .attr("width", this.WIDTH)
            .attr("height", this.HEIGHT)
            .append("title").text(d=> d)

    }

    render(){

        

        return(
          <svg ref={node => this.node = node} 
          width={this.props.size[0]} height={this.props.size[1]} />
          )
    }
}

export default StatusTimeline;
