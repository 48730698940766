import React from 'react'
import { Route, Redirect } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GET_USER } from '../apollo/queries/authentication'

const isAuthenticated = false

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loading, error, data } = useQuery(GET_USER)
  const isAuthenticated = !loading && data
  return(
  <Route {...rest} render={props => (
    isAuthenticated ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)
}

export default PrivateRoute; 
