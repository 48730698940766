import { gql } from '@apollo/client'
import { LOCATION_DATA } from './locations'


export const UPDATE_DEPLOYMENT = gql`
  mutation UpdateDeployment($input: DeploymentUpdateGenericType!) {
    updateDeployment(newDeployment: $input){
        ok
        deployment {
              id
              time
              location {
                ...LocationData
              }
              instrument {
                id
              }
              user {
                username
              }
              description
        }
    }
  }
  ${LOCATION_DATA}
`;

export const CREATE_DEPLOYMENT = gql`
  mutation CreateDeployment($input: DeploymentCreateGenericType!) {
    createDeployment(newDeployment: $input){
        ok
        deployment {
              id
              time
              location {
                ...LocationData
              }
              instrument {
                id
              }
              user {
                username
              }
              description
        }
    }
  }
  ${LOCATION_DATA}
`;

export const DELETE_DEPLOYMENT = gql`
  mutation DeleteDeployment($input: ID!) {
    deleteDeployment(id: $input){
        ok
        deployment {
              id
              time
              location {
                ...LocationData
              }
              instrument {
                id
              }
              user {
                username
              }
              description
        }
    }
  }
  ${LOCATION_DATA}
`;
 
